import React, {Component} from "react";

import "./conditions-roadmap-mobile.css"
import "./conditions-roadmap.css"
import {ReactComponent as Check} from "./check.svg";
import {ReactComponent as File} from "./file.svg";
import {ReactComponent as Search} from "./search.svg";
import {ReactComponent as Mobile} from "./mobile.svg";
import {ReactComponent as ThumbUp} from "./thumbs-up.svg";
import logo from "./shortlogo.png"

class ConditionsContent extends Component {

    render() {
        let roadmap =
            [
                {
                    id: "11",
                    icon: (<Check className={"icon"}/>),
                    align: "top"
                },
                {
                    id: "12",
                    title: "ПОИСК",
                    text: "Теперь выбираем курсы в Праге",
                    align: "top"
                },
                {
                    id: "13",
                    isImg: true,
                    img: logo,
                    align: "top"
                },
                {
                    id: "14",
                    title: "ЗАЯВКА",
                    text: "Написал, позвонил, в общем связался",
                    align: "top"
                },
                {
                    id: "15",
                    icon: (<File className={"icon"}/>),
                    align: "top"
                },
                {
                    id: "16",
                    title: " ПРАГА",
                    text: "Приехал, встретили, учеба, класс))!",
                    align: "top"
                },
                {
                    id: "21",
                    title: "РЕШИЛ",
                    text: "Поеду учитсься в Европу!",
                    align: "bottom"
                },
                {
                    id: "22",
                    icon: (<Search className={"icon"}/>),
                    align: "bottom"
                },
                {
                    id: "23",
                    title: "VERTEX",
                    text: "Нашел класные подходит!",
                    align: "bottom"
                },
                {
                    id: "24",
                    icon: (<Mobile className={"icon"}/>),
                    align: "bottom"
                },
                {
                    id: "25",
                    title: "ЗАПИСАЛИ",
                    text: "Получил документы, получил визу",
                    align: "bottom"
                },
                {
                    id: "26",
                    icon: (<ThumbUp className={"icon"}/>),
                    align: "bottom"
                },
            ]
        return (
            <div className="conditions-roadmap-items">
                {
                    roadmap.map((item, i) => (
                        <div
                            className={`conditions-raodmap-item${item.id} conditions-raodmap-item conditions-raodmap-item-icon-align-${item.align}`}
                            id={i}
                        >
                            {
                                Boolean(item.isImg) ?
                                    (
                                        <img
                                            src={item.img}
                                            alt="logo"
                                            className={"conditions-raodmap-item-icon conditions-raodmap-item-logo"}
                                        />
                                    )
                                    :
                                    Boolean(item.icon) ?
                                        (
                                            <div className={"conditions-raodmap-item-icon"}>
                                                {item.icon}
                                            </div>
                                        )
                                        :
                                        (
                                            <div className={"conditions-roadmap-content"}>
                                                <h5 className={"conditions-roadmap-content-title"}>{item.title}</h5>
                                                <div className={"conditions-roadmap-content-text"}>{item.text}</div>
                                            </div>
                                        )
                            }
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default ConditionsContent;