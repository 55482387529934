import React, {Component} from "react";
import "./adv-header.css"
import "./adv-header-mobile.css"
import {scroller} from "react-scroll";

class AdvHeader extends Component {
    scrollToSection = () => {
        scroller.scrollTo("start", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    render() {
        const {title, text, color, oldPrice, newPrice, priceText, url, btn, aboveTitle} = this.props;
        return (
            <header
                className="vrtx-adv-header"
                style={Boolean(color) ? {background: color} : {background: "#5089C6"}}
            >
                {
                    Boolean(url) && <img src={url} alt={`vertexschool ${title}`} className={"vrtx-adv-header-bg scaleFrom0"}/>
                }
                <div className={"vrtx-adv-header-wrap"}>
                    <div className="vrtx-adv-header-content slideInFromLeft">
                        {
                            Boolean(aboveTitle) && <h2>{aboveTitle}</h2>
                        }
                        <h1>{title}</h1>
                        <p>{text}</p>
                        <button className={"scroll-down"} onClick={this.scrollToSection}>
                            { Boolean(btn) ? btn : "ПОДРОБНЕЕ"}
                        </button>
                    </div>
                </div>
            </header>
        );
    }
}

export default AdvHeader;