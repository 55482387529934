import React, {Component} from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import AboutUs from "./AboutUs/AboutUs";
import Programs from "./Programs/Programs";
import Contacts from "./Contacts/Contacts";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";

import "./main.css"
import AboveNavbar from "./Components/AboveNavbar/AboveNavbar";
import Home from "./Home/Home";
import Accommodation from "./Accommodation/Accommodation";
import FormFooter from "./Components/FormFooter/FormFooter";
import ReactPixel from "react-facebook-pixel"
class App extends Component {

    componentDidMount() {
        // const options = {
        //     autoConfig: true,
        //     debug: false,
        // };
        // ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_TOKEN, options);
        // ReactPixel.pageView();
    }

    render() {
        const baseNavbar = [
            { to: "/home/about-us", name: "О НАС" },
            { to: "/home/categories#ctu", name:  "ЧТУ"},
            { to: "/home/programs#fundament", name: "ПРОГРАММЫ" },
            { to: "/home/accommodation#strahov", name: "ПРОЖИВАНИЕ" },
        ]
        const extendedNavbar = baseNavbar.concat([
            { to: "/home/nostrification#procedure", name: "НОСТРИФИКАЦИЯ" },
            { to: "/home/insurance#important", name: "СТРАХОВАНИЕ" },
            { to: "/home/visa", name: "ВИЗА" },
            { to: "/home/useful#network", name: "ПРИГОДИТСЯ" }
        ])

        return (
            <Router>
                <div className="App">
                    <AboveNavbar/>
                    <Switch>
                        <Route exact path={"/home"} render={props =>
                            <Navbar
                                {...props}
                                items={baseNavbar.concat({ to: "/home/contacts", name: "КОНТАКТЫ" })}
                            />
                        }/>
                        <Route path={"*"} render={props =>
                            <Navbar
                                {...props}
                                items={extendedNavbar.concat({ to: "/home/contacts", name: "КОНТАКТЫ" })}
                            />
                        }/>
                    </Switch>
                    <Switch>
                        <Route path="/home/about-us" component={AboutUs}/>
                        <Route path="/home/programs" component={Programs}/>
                        <Route path="/home/accommodation" component={Accommodation}/>
                        <Route path="/home/contacts" component={Contacts}/>
                        <Route path={["/home", ""]} component={Home}/>
                    </Switch>
                    <Switch>
                        <Route path={["", "/home", "/home/contacts"]} exact component={Footer}/>
                        <Route path={"*"} component={FormFooter}/>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
