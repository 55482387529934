import React, {Component} from 'react';
import "./history.css"
import BaseHeader from "../../Components/BaseHeader/BaseHeader";
import H2TitleWithText from "../../Template/TitleWithText/H2TitleWithText";
import headerImg from "./history.jpg"
import H2TwoTitlesWithTwoTexts from "../../Template/TwoTitlesWithTwoTexts/H2TwoTitlesWithTwoTexts";
import BaseUnderHeader from "../../Components/UnderHeader/BaseUnderHeader";

class History extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <BaseHeader
                    title={"История"}
                    text={<p>
                        Долгая и насыщенная история Университета заслуживает внимания и изучения. Мы всегда будем рады поделиться с вами главными вехами его на его долгом пути.
                    </p>}
                    url={headerImg}
                />
                <BaseUnderHeader
                    title={"вехи в становлении"}
                />
                <div className={"home-history start"}>
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1705"}
                        rightTitle={"1707"}
                        leftText={<p>
                            Специалист по фортификациям Иосиф Кристиан Вилленберг обратился к императору Леопольду с просьбой разрешить, чтобы «шесть человек дворянского сословия, четверо рыцарского и двое мещанского обучались инженерному искусству».
                        </p>}
                        rightText={<p>
                            18-го января император Иосиф I послал комиссарам чешского сейма рескрипт, в котором выражает согласие с предложением Вилленберга и повелевает обсудить его.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1716"}
                        rightTitle={"1717"}
                        leftText={<p>
                            В связи с тем, что чешские сословия не ответили на рескрипт, в октябре 1716г. Вилленберг повторно посылает свой проект, на этот раз не только императору (Карлу VI), но и чешским сословиям.
                        </p>}
                        rightText={<p>
                            Указом чешских сословий от 9-го ноября была создана профессорская кафедра инженерии, руководство которой поручено Вилленбергу; курс обучения начался 7-го января 1718г. Кроме фортификационного искусства, изучались и гражданские специальности, такие как землемерие, картография, дренажные работы, конструкция подъемных механизмов и др.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1726 — 1767"}
                        rightTitle={"1798"}
                        leftText={<p>
                            Преемником Вилленберга был Ян Франц Шор, который ввел в программу обучения преподавание оптики, перспективы, черчения и географии.
                            Третьим профессором инженерной школы стал Антонин Лингарт Хергет. При нем профессорская кафедра инженерии трансформировалась (в 1787г.) на штатную профессуру философского факультета Пражского университета.
                        </p>}
                        rightText={<p>
                            Университетский профессор математики и член придворной комиссии по ревизии государственного образования Франц Йозеф Герстнер внес предложение о создании технического университета, в котором бы инженерные науки, по образцу Парижской политехнической школы, были тесно взаимосвязаны с изучением математики и точных наук, как этого требовала индустриализация чешских земель.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1803"}
                        rightTitle={"1815"}
                        leftText={<p>
                            В марте 1803 г. австрийский император Франц I подписал указ о создании Чешского сословного политехнического института; обучение в новом политехническом вузе началось 10-го ноября 1806 г., а директором института был назначен Ф.Й. Герстнер.
                        </p>}
                        rightText={<p>
                            С 8-го сентября Политехнический институт перестал быть частью университета и получил статус самостоятельного учебного заведения.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1863 — 1869"}
                        rightTitle={"1875"}
                        leftText={<p>
                            С 23-го ноября, когда император Австрийской империи и король Богемии Франц Иосиф I утвердил т.н. Органический статут Политехнического института, началась краткая история пражского двуязычного политехнического вуза. По прошествии шести лет (18. 4. 1869) монарх утраквистскую школу закрыл и в то же время дал согласие с появлением двух новых: Чешского и Немецкого политехнического института Чешского королевства.
                        </p>}
                        rightText={<p>
                            Чешский и Немецкий политехнический институты (до сих пор земские учреждения) были переданы в государственное управление.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1879"}
                        rightTitle={"1901"}
                        leftText={<p>
                            Оба учреждения получили новые названия: Императорская и королевская Чешская высшая техническая школа в Праге и K. k. Deutsche Technische Hochschule in Prag.
                        </p>}
                        rightText={<p>
                            Имперский закон от 13-го апреля предоставил высшим техническим школам право присваивать степени докторов технических наук (Dr. techn.) и почетные титулы докторов технических наук (Dr. techn. h. c.).
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1902"}
                        rightTitle={"1920 — 1939"}
                        leftText={<p>
                            Профессорский корпус Императорской и королевской Чешской высшей технической школы в Праге рекомендовал Министерству культов и образования, чтобы женщины могли приниматься в институт в качестве действительных слушателей.
                        </p>}
                        rightText={<p>
                            Постановлением Министерства образования и национального просвещения Чехословацкой республики от 1-го сентября 1920г. возник Чешский высший технический институт — ЧВУТ (ныне ЧТУ – Чешский технический университет в Праге) как объединение семи технических вузов: строительной инженерии, культурной инженерии, архитектуры и наземной инженерии, машиностроительной и электротехнической инженерии, химико-технологической инженерии, сельскохозяйственной и лесной инженерии, специальных наук. В 1921г. Институт культурной инженерии как отдельное учреждение был ликвидирован путем включения в состав Института строительной инженерии. С 1929г. равноправной частью ЧВУТ стал также Институт торговли, до тех пор только трехлетний.
                            Пражская немецкая техническая школа в 1918г. изменила свое название на Deutsche Technische Hochschule Prag.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1939"}
                        rightTitle={"1945"}
                        leftText={<p>
                            17-го ноября 1939г. решением рейхспротектора Константина фон Нейрата чешские вузы были закрыты.
                        </p>}
                        rightText={<p>
                            Вскоре после окончания войны (4-го июня 1945г.) обучение в ЧВУТ (ЧТУ) было в полном объеме возобновлено, причем в тех же вузах, которые работали в 1939 году. Немецкая высшая техническая школа в Праге 16-го октября декретом президента Бенеша была закрыта.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1950 -1960"}
                        rightTitle={"1976"}
                        leftText={<p>
                            После ряда организационных изменений, осуществленных в период 1950 — 1960гг., ЧВУТ (ЧТУ) имела четыре факультета: машиностроения, строительный, электротехники, технической и ядерной физики (с 1967г. ядерных технологий и физического инжиниринга).
                        </p>}
                        rightText={<p>
                            ЧВУТ (ЧТУ) расширилась — добавился Факультет архитектуры.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1989"}
                        rightTitle={"1992"}
                        leftText={<p>
                            Постепенное внедрение структурированного обучения; обучение проводится по программам бакалавриата, магистратуры и докторантуры.
                        </p>}
                        rightText={<p>
                            Завершение обширной реконструкции Бетлемской (Вифлеемской) капеллы, которая служит в качестве аудитории ЧВУТ (ЧТУ).
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"1993"}
                        rightTitle={"2005"}
                        leftText={<p>
                            Началось обучение на только-что открытом Факультете транспорта.
                        </p>}
                        rightText={<p>
                            К имеющимся шести факультетам ЧТУ добавился Факультет биомедицинской инженерии.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"2007"}
                        rightTitle={"2009"}
                        leftText={<p>
                            В течение «Недели техники», проходившей от 15 до 19 января, школа отмечала 300-летие своего создания. Частью «Недели техники» был концерт Чешской филармонии в «Рудольфинуме» 16-го января. На следующий день в ходе торжественного заседания научного совета состоялась церемония вручения почетных докторских степеней президенту ЧР Вацлаву Клаусу, профессору University of Surrey Джозефу Киттлеру и президенту фирмы SKANSKA AB Стюарту Грэхэму. Главным событием стало торжественное заседание Научного совета ЧТУ 16-го января, проходившее в Испанском зале Пражского града с участием президента страны, членов правительства ЧР и представителей зарубежных университетов. «Неделя техники» завершилась вернисажем выставки «Техника глазами техника».
                        </p>}
                        rightText={<p>
                            1-го июля восьмым факультетом школы стал Факультет информационных технологий.
                            Помимо восьми факультетов, в ЧТУ входят также Институт им. Клокнера (научно-исследовательский институт в области строительства), Институт высших исследований им. Масарика, Институт физкультуры и спорта, Вычислительной и информационный центр, Технологический и инновационный центр, Научно-исследовательский центр промышленного наследия, Институт технической и экспериментальной физики ЧТУ, Департамент строительства и инвестиционной деятельности ЧТУ, Центральная библиотека ЧТУ и три целевые структурные подразделения: Ректорат ЧТУ, Управление целевых учреждений, «Чешская техника» — издательство ЧТУ.
                        </p>}
                    />
                    <H2TwoTitlesWithTwoTexts
                        leftTitle={"2012"}
                        rightTitle={"2013"}
                        leftText={<p>
                            Университетский центр энергетической эффективности зданий ЧТУ (UCEEB) приобретает статус высшей школы. Этот междисциплинарный научно-исследовательский проект является реакцией на один из наиболее важных текущих приоритетов Европейского союза, а именно, оптимизацию экономии энергии в зданиях.
                        </p>}
                        rightText={<p>
                            Основание Чешского института информатики, робототехники и технической кибернетики (CIIRC). Институт CIIRC ставит перед собой цель стать научно-исследовательским заведением международного уровня и в сотрудничестве с факультетами ЧТУ — видным учебным центром докторского и постдокторского образования. В начале своей деятельности CIIRC в первую очередь сосредотачивает свои усилия на подготовку проекта «Рамочные программы Европейского Союза по развитию научных исследований и технологий» — ЧТУ CIIRC.
                        </p>}
                    />
                    <H2TitleWithText
                        minWidth={"75vw"}
                        title={"2014"}
                        text={<p>
                            Завершение строительства Университетского центра энергетической эффективности зданий ЧТУ (UCEEB) в Буштеграде. Штаб-квартира центра является примером использования новейших тенденций и доступных технологий в области энергосбережения в строительстве. Таким образом само здание служит для экспериментальных целей.
                        </p>}
                    />
                </div>
            </div>
        );
    }
}

export default History;