import React, {Component} from 'react';
import "./insurance.css"
import TitleWithTextAndPhoto from "../../Template/TitleWithTextAndPhoto/H2TitleWithTextAndPhoto";
import BaseHeader from "../../Components/BaseHeader/BaseHeader";
import TitleAndTextWithBackground from "../../Template/TitleAndTextWithBackground/TitleAndTextWithBackground";
import H3TwoTitlesWithTwoLists from "../../Template/TwoTitlesWithTwoLists/H3TwoTitlesWithTwoLists";
import pvzp from "./pvzp-text.png"
import insuranceHeader from "./insurance-header.jpg"
import BaseUnderHeader from "../../Components/UnderHeader/BaseUnderHeader";

class Insurance extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <BaseHeader
                    title={"Медицинское страхование"}
                    text={<p>
                        В Европе медицинское обслуживание является платным. И Чешская Республика не является исключением. В случае если студент обратился в медицинское учреждение, то расходы возьмёт на себя его страховая компания.
                    </p>}
                    url={insuranceHeader}
                    color={"#1597BB"}
                />
                <BaseUnderHeader
                    title={"важная составляющая"}
                />
                <div className={"home-insurance start"}>
                    <TitleWithTextAndPhoto
                        title={"pVZP"}
                        text={<p>
                            Наши курсы предоставляют медицинское страхование pVZP, являющееся официальным подразделением мед. страхования для иностранцев, чешской страховой компании VZP, которая является лидером в области медицинского страхования, и крупнейшей компанией в Чехии. Имеющей многолетние традиции, и имеющей самую большую партнёрскую сеть больниц и докторов.
                            Страхование соответствует требованиям закона, О проживании иностранцев на территории Чешской Республики, и изменениям, вынесенными в законодательство о мед. страховании.
                            Комплексное медицинское страхование pVZP, обеспечивает медицинское обслуживание в той степени, которое сопоставимо с Чешским общественным медицинским страхованием. Наше страхование обладает многими важными аспектами, которые могут возникнуть у студентов в период нахождения в Чехии.
                            Без оформленного медицинского страхования приезд в Чешскую республику невозможен.
                        </p>}
                        path={pvzp}
                        noShadow={true}
                    />
                    <TitleAndTextWithBackground
                        title={"Стоимость страхования"}
                        text={<p>
                            <b style={{fontStyle: "italic"}}>
                                Полис комплексного страхования на 12 месяцев стоит 18 500 чешских крон (примерно 785 евро — цена в евро зависит от моментального курса чешской кроны в ЦБ Чехии на момент покупки полиса)
                                Стоимость комплексного страхования PVZP покрывает весь срок обучения на курсах.
                                Также в стоимость страхового полиса входит круглосуточная сервисная ассистентская служба, которую предоставляет компания AXA Assitance. Телефон на службу находится на задней части карточки страховки.
                            </b>
                        </p>}
                    />
                    <H3TwoTitlesWithTwoLists
                        leftTitle={"В ассистентские услуги входят"}
                        rightTitle={"Информативная помощь"}
                        leftItems={[
                            "Медицинская ассистенция",
                            "Рекомендация больниц и докторов",
                            "Рекомендации и инструкции в случае ситуаций связанных со здоровьем",
                            "Мониторинг состояния здоровья во время госпитализации",
                            "Обеспечение перевозки останков в родную страну в случае смерти"
                        ]}
                        rightItems={[
                            "Информация о больницах и докторах с которыми сотрудничает PVZP",
                            "Предоставление информации о ликвидности страхового случая мед. учреждению",
                            "Предоставление информации о ликвидности страхового случая студенту"
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Insurance;