import {Component} from "react";
import "./instagram.css"
import {ElfsightWidget} from "react-elfsight-widget";

class Instagram extends Component {

    render() {
        return (
            <div className={"vrtx-instagram"}>
                <ElfsightWidget widgetID={process.env.REACT_APP_WIDGET_ID}/>
            </div>
        );
    }
}
export default Instagram;