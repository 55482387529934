import React, {Component} from 'react';

import "./base-sticky-header.css"
import "./base-sticky-header-mobile.css"

import BaseHashLink from "./HashLink/BaseHashLink";
import StickyNavLink from "./StickyNavLink/StickyNavLink";

class BaseStickyHeader extends Component {

    render() {
        const {items, className, handleChange, link} = this.props;
        return (
            <div className={`base-sticky-header-list ${className} start`}>
                {
                    !Boolean(link) ?
                        items.map((item, key) => (
                            <BaseHashLink
                                text={item.name}
                                to={item.to}
                                className={"base-sticky-header-list-item"}
                                key={key}
                                handleChange={handleChange}
                            />
                        )) :
                        items.map((item) => (
                            <StickyNavLink
                                text={item.name}
                                to={item.to}
                                className={"base-sticky-header-list-item"}
                            />
                        ))
                }
            </div>
        );
    }
}

export default BaseStickyHeader;