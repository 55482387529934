import React, {Component} from "react";

import "./list-with-background.css"
import "./list-with-background-mobile.css"
import {ReactComponent as ExclamationCircle} from "./exclamation-circle.svg";

class ListWithBackground extends Component {
    render() {
        const {items} = this.props;
        return (
            <div className={"list-with-bg"}>
                <div className="list-with-bg-container">
                    <h3><ExclamationCircle className={"icon"}/></h3>
                    <ul>
                        {
                            items.map(item => (
                                <li><span>•</span><div>{item}</div></li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default ListWithBackground;