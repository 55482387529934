import React, {Component} from 'react';

import "./home-header.css"
import "./home-header-mobile.css"
import FastNavigation from "./FastNavigation/FastNavigation";
import HomeHeaderTop from "./HomeHeaderTop/HomeHeaderTop";

class HomeHeader extends Component {
    render() {
        return (
            <header className="vrtx-hero-section">
                <HomeHeaderTop/>
                <FastNavigation/>
            </header>
        );
    }
}

export default HomeHeader;