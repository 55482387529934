import React, {Component} from 'react';

import "./base-header.css"
import "./base-header-mobile.css"
import "../../animations.css"
import {scroller} from "react-scroll";

class BaseHeader extends Component {
    scrollToSection = () => {
        scroller.scrollTo("start", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    render() {
        const {title, text, url, color, btn, aboveTitle} = this.props;
        return (
            <header
                className="vrtx-base-header"
                style={Boolean(color) ? {background: color} : {background: "#5089C6"}}
            >
                {
                    Boolean(url) && <img src={url} alt={`vertexschool ${title}`} className={"vrtx-base-header-bg scaleFrom0"}/>
                }
                <div className={"vrtx-base-header-overlay"}/>
                <div className={"vrtx-base-header-wrap"}>
                    <div className="vrtx-base-header-content slideInFromLeft">
                        {
                            Boolean(aboveTitle) && <h2>{aboveTitle}</h2>
                        }
                        <h1>{title}</h1>
                        <p>{text}</p>
                        <button className={"scroll-down"} onClick={this.scrollToSection}>
                            { Boolean(btn) ? btn : "ПОДРОБНЕЕ"}
                        </button>
                    </div>
                </div>
            </header>
        );
    }
}

export default BaseHeader;