import {Component} from "react";
import "./vrtx-switch.css"
import "./vrtx-switch-mobile.css"

class VrtxSwitch extends Component {

    render() {
        const {title} = this.props;
        return (
            <div className={"vrtx-switch"}>
                <div className={"vrtx-switch-top"}>
                    <div className="vrtx-switch-top-content">
                        <h1>{title}</h1>
                        <div className="vrtx-switch-top-button-group">
                            <button/>
                            <button/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VrtxSwitch;