import React, {Component} from "react";
import "./two-titles-with-two-texts.css"
import "./two-titles-with-two-texts-mobile.css"

class H2TwoTitlesWithTwoTexts extends Component {
    render() {
        const {leftTitle, leftText, rightTitle, rightText} = this.props;
        let leftTitleArray = leftTitle.split(" ");
        let leftTitle1 = leftTitleArray.slice(0, leftTitleArray.length - 1);
        let leftTitle2 = leftTitleArray[leftTitleArray.length - 1]
        let rightTitleArray = rightTitle.split(" ");
        let rightTitle1 = rightTitleArray.slice(0, rightTitleArray.length - 1);
        let rightTitle2 = rightTitleArray[rightTitleArray.length - 1]
        return (
            <div className={`base-wo-titles-with-two-texts`}>
                <div className={"base-wo-titles-with-two-texts-left"}>
                    {
                        Boolean(leftTitle) && <div className={"base-wo-titles-with-two-texts-title"}>
                            <h2>
                                {Boolean(leftTitle1) ? leftTitle1.join(" ") + String.fromCharCode(160) : ""} <span>{`${leftTitle2}`}</span>
                            </h2>
                        </div>
                    }
                    <div className={"base-wo-titles-with-two-texts-left-text"}>
                        {leftText}
                    </div>
                </div>
                <div className={"base-wo-titles-with-two-texts-right"}>
                    {
                        Boolean(rightTitle) && <div className={"base-wo-titles-with-two-texts-title"}>
                                <h2>
                                    {Boolean(rightTitle1) ? rightTitle1.join(" ") + String.fromCharCode(160) : ""} <span>{`${rightTitle2}`}</span>
                                </h2>
                        </div>
                    }
                    <div className={"base-wo-titles-with-two-texts-right-text"}>
                        {rightText}
                    </div>
                </div>


            </div>
        )
    }
}

export default H2TwoTitlesWithTwoTexts;