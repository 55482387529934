import React, {Component} from 'react';

import "./sticky-nav-link.css"
import {NavLink} from "react-router-dom";

class StickyNavLink extends Component {

    render() {
        const {text, to, className} = this.props;
        return (
            <div className={`sticky-nav-link-wrap ${className}`}>
                <NavLink
                    to={to}
                    activeClassName={"sticky-nav-link-wrap-active"}
                >
                    {text}
                </NavLink>
            </div>
        );
    }
}

export default StickyNavLink;