import React, {Component} from 'react';

import "./base-nav-link.css"
import "./base-nav-link-mobile.css"
import {NavLink} from "react-router-dom";

class BaseNavLink extends Component {

    render() {
        const {text, to, linkClassName, className} = this.props;
        return (
            <div className={`base-nav-link ${className}`}>
                <NavLink
                    to={to}
                    activeClassName="base-nav-link-active-item"
                    className={linkClassName}
                >
                    {text}
                </NavLink>
            </div>
        );
    }
}

export default BaseNavLink;