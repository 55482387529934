import React, {Component} from "react";
import "./categories.css"
import "./categories-mobile.css"
import BaseContentSlider from "../../Components/ContentSlider/BaseContentSlider";
import BaseHeader from "../../Components/BaseHeader/BaseHeader";
import BaseStickyHeader from "../../Components/StickyHeader/BaseStickyHeader";
import H2TitleWithTextAndPhoto from "../../Template/TitleWithTextAndPhoto/H2TitleWithTextAndPhoto";
import TextWithBackground from "../../Template/TextWithBackground/TextWithBackground";
import bg from "./cat-bg.jpg"
import cvutText from "./cvut-text.jpg"
import catHeader from "./cvut-header.jpg"
import cat1 from "./uni1.1.jpg"
import cat2 from "./uni2.2.jpg"
import cat3 from "./uni3.3.jpg"

import departmentImg from "./department.jpg"
import resourceImg from "./resource.jpg"

import TriplePhoto from "../../Template/TriplePhoto/TriplePhoto";
import H2TitleWithText from "../../Template/TitleWithText/H2TitleWithText";
import Path from "../../Components/Path/Path";

class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            currentHash: "#ctu",
            items: [
                { to: "#ctu", name: "Университет"},
                { to: "#whyctu", name: "Почему ЧТУ" },
                { to: "#resource", name: "Исследования"},
                { to: "#department", name: "Кафедра языков"}
            ]
        }

        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let hash = window.location.hash;
        if (Boolean(hash)) {
            this.setState({current: this.getCurrent(hash)})
        }
        window.scrollTo(0, 0)
    }

    getCurrent = (hash) => {
        const {items} = this.state;
        return items.findIndex(item => item.to === hash);
    }

    handleChange = (event, to) => {
        this.setState({currentHash: to, current: this.getCurrent(to)})
    }

    getTouches = (event) => {
        return event.touches ||
            event.originalEvent.touches;
    }

    handleNext = () => {
        const {current, items} = this.state;
        let next = current + 1;
        if (next > items.length - 1) {
            next = current;
        }
        this.setState({current: next, currentHash: items[next].to})
    }

    handlePrev = () => {
        const {current, items} = this.state;
        let prev = current - 1;
        if (prev < 0) {
            prev = 0
        }
        this.setState({current: prev, currentHash: items[prev].to})
    }

    handleTouchStart = (event) => {
        const firstTouch = this.getTouches(event)[0];
        let xDown = firstTouch.clientX;
        let yDown = firstTouch.clientY;
        this.setState({xDown: xDown, yDown: yDown})
    };

    handleTouchMove = (event) => {
        const {xDown, yDown} = this.state;
        if ( ! xDown || ! yDown ) {
            return;
        }

        let xUp = event.touches[0].clientX;
        let yUp = event.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                this.handleNext();
            } else {
                this.handlePrev();
            }
        }
        /* reset values */
        this.setState({xDown: null, yDown: null})
    };


    render() {
        const {current, items} = this.state;
        const ctu =
            (<div>
                <H2TitleWithTextAndPhoto
                    title={"ЧТУ в Праге"}
                    width={"50%"}
                    text={<p>
                        Чешский технический университет в Праге - один из крупнейших и старейших технических университетов Европы. Был основан рескриптом императора Иосифа I от 18-го января 1707 г. Это также один из старейших чешских вузов, который в 2007 году отметил 300-летнюю годовщину своего основания. Один из сильнейших вузов страны готовит специалистов и учёных в технических областях, создаёт потенциал в научных и педагогических исследованиях, в соответствии с требованиями общества и мировыми тенденциями.
                        Университет поддерживает обширные связи с техническими вузами разных стран, в том числе России и СНГ, организует международные программы обмена студентами и прохождения практики, а также обеспечивает поддержку при проведении научных исследований. Международные конференции, проводимые в стенах вуза, позволяют студентам обмениваться актуальной информацией и заявлять свои проекты на высоком уровне.
                    </p>}
                    path={cvutText}
                />
                <TextWithBackground text={<p>
                    <b style={{fontStyle: "italic"}}>
                        Чешский вуз с более чем трехсотлетней историей предлагает студентам программы обучения на 8 факультетах (строительный, машиностроения, электротехнический, ядерных технологий и физического инжиниринга, архитектуры, транспорта, биомедицинской инженерии, информационных технологий) на которых учатся более 17 000 студентов. В настоящее время ЧТУ предлагает своим студентам более 220 аккредитованных учебных программ.
                    </b>
                </p>}/>
            </div>)
        const whyctu = (<div>
            <H2TitleWithText
                title={"Почему учиться в ЧТУ в Праге?"}
                text={<p>
                    В Чешском техническом университете в Праге можно проходить курс обучения во всех составных частях структуры высшего технического образования. Это трехлетний и четырехлетний бакалавриат с присвоением титула бакалавр (Вс.), магистерские специальности с продолжительностью обучения от 1,5 до 2 лет, после окончания которых присваиваются титулы «инженер» (Ing.), а также трехлетние или четырехлетние докторские программы, завершающиеся присвоением титула «доктор» (Ph.D.).
                    Старейшими факультетами являются строительный, машиностроения, электротехнический и факультет ядерных технологий и физического инжиниринга. Позже добавились факультеты архитектуры и транспорта, а самыми молодыми стали факультеты биомедицинской инженерии и информационных технологий.
                    Приемную процедуру при поступлении каждый факультет устанавливают самостоятельно. В большинстве случаев речь идет о письменном тесте в случае учебных программ бакалавриата. Это в первую очередь, математика и физика. При поступлении на факультет архитектуры также требуется сдать творческий экзамен из двух частей связанной с данной специальностью. Если на средней школе у вас была хорошая успеваемость, или если вы участвовали в олимпиадах или других специальных мероприятиях, рекомендуем внимательно прочитать условия приема — может случиться, что вы удовлетворяете им без вступительных экзаменов.
                    Студентам имеют возможность в рамках учебной программы учиться как Европе так и во всем мире.
                    В течение обучения вы можете принять участие в многочисленных студенческих конкурсах или специальных профессиональных лекциях, семинарах и консультациях, проводимых известными личностями и гостями университета.
                </p>}
            />
            <TextWithBackground text={<p>
                <b style={{fontStyle: "italic"}}>
                    Студентам-инвалидам Университет предлагаем возможность приспособить учебную среду и процесс обучения их специфическим потребностям. Обслуживание студентов и абитуриентов с инвалидностью обеспечивает Центр поддержки студентов с особыми потребностями ELSA.
                </b>
            </p>}/>
        </div>)
        const resource =
            (<div>
                <H2TitleWithTextAndPhoto
                    width={"50%"}
                    title={"Наука и исследования"}
                    text={<p>
                        Все восемь факультетов университета, а также другие звенья его структуры — Институт им. Клокнера, Институт высших исследований им. Масарика и Институт технической и экспериментальной физики — участвуют в исследованиях. ЧТУ как престижный вуз, акцентирующий внимание на качестве научной и научно-исследовательской работы, является полноценной составной частью европейского исследовательского и образовательного пространства.
                        Университет сотрудничает с соответствующими международными и национальными организациями в рамках научно-исследовательской деятельности студентов и их разработок. Предоставляет информационные, консультационные и административные услуги для студентов, вносящих заявки и принимающих участие в осуществлении чешских и иностранных научно-исследовательских проектов. Путем организации активного участия разработчиков в выставках и семинарах, способствуем популяризации и внедрению результатов науки и научных исследований в жизнь.
                    </p>}
                    path={resourceImg}
                />
                <TextWithBackground text={<p>
                    <b style={{fontStyle: "italic"}}>
                        В ЧТУ имеется много учреждений, занимающихся фундаментальными и прикладными исследованиями. В течение многих лет они являются центрами, в которых как фундаментальные, так и прикладные исследования ведутся на высоком международном уровне.
                        В качестве одного из многих стимулов используются средства из Фонда поддержки научной и научно-исследовательской деятельности.
                        Также существует ежегодная Премия ректора в разных категориях.
                    </b>
                </p>}/>
            </div>)
        const department =
            (<div>
                <H2TitleWithTextAndPhoto
                    width={"50%"}
                    title={"Кафедра языков"}
                    text={<p>
                        История Кафедры языков, имеет давние традиции. Кафедра возникла в пятидесятых годах минувшего столетия благодаря одному из ведущих русистов страны профессору Леонтию Копецкому, выходцу из России. Под его руководством, к примеру, были созданы шеститомный «Большой русско-чешский словарь» (1952-1964), «Школьный русско-чешский словарь» (1955) и «Чешско-русский словарь» (1976).
                        Кафедра языков в настоящее время является одной из лучших образовательных организаций в Праге в области языков и языковой подготовки. Кафедра отвечает современным требованиям к образованию, особое внимание обращается на качество языковой подготовки а также на рациональную организацию учебного года. Это, в свою очередь, сказывается на более свободном чтении и понимании научно-технической литературы, расширении потенциального словаря студентов, более глубоком знании языка. Что в свою очередь положительно сказывается на уровне обучения наших студентов.
                    </p>}
                    path={departmentImg}
                />
                <TextWithBackground text={<p>
                    <b style={{fontStyle: "italic"}}>
                        Обучение русскому языку было в то время обязательным и преподавалось 4 семестра. Первоначально кафедра относилась к ректорату ЧТУ в Праге, но в 1956 для удобства преподавания, и повышения качества она была разделена по факультетам тогдашнего политехнического института.
                    </b>
                </p>}/>
            </div>)
        return (
            <div>
                <BaseHeader
                    title={"Чешский Технический Университет"}
                    text={<p>
                        Один из крупнейших Университетов Европы, с долгой и богатой историей. Крупными научными достижениями в различных сферах и направлениях современной науки.
                    </p>}
                    url={catHeader}
                    bg={bg}
                />
                <BaseStickyHeader
                    items={items}
                    handleChange={this.handleChange}
                    className={"custom-categories-sticky-header start"}
                />
                <div className={"categories-page-section"}>
                    <div
                        className={"categories-page-content"}
                        onTouchStart={this.handleTouchStart}
                        onTouchMove={this.handleTouchMove}
                    >
                        <Path/>
                        <BaseContentSlider
                            items={[
                                ctu,
                                whyctu,
                                resource,
                                department
                            ]}
                            current={current}
                            handleTouchStart={this.handleTouchStart}
                            handleTouchMove={this.handleTouchMove}
                        />
                        <TriplePhoto
                            style={{marginBottom: "160px"}}
                            left={cat1}
                            middle={cat2}
                            right={cat3}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Categories;