import React, {Component} from "react";
import "./full-width-bg-with-text.css"
import "./full-width-bg-with-text-mobile.css"
import {ReactComponent as ExclamationCircle} from "./exclamation-circle.svg";

class FullWidthBgWithText extends Component {
    render() {
        const {text} = this.props;
        return (
            <div className={"full-width-with-text"}>
                <div className={"full-width-with-text-container"}>
                    <ExclamationCircle className={"icon"}/>
                    {text}
                </div>
            </div>
        );
    }
}
export default FullWidthBgWithText;