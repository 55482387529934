import React, {Component} from 'react';

import "./fast-navigation.css"
import "./fast-navigation-mobile.css"
import {Link} from "react-router-dom";

class FastNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true
        }
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter = (event) => {
        event.preventDefault();
        this.setState({active: false})
    }
    handleMouseLeave = (event) => {
        event.preventDefault();
        this.setState({active: true})
    }

    render() {
        const {active} = this.state;
        return (
            <div className="vrtx-fast-navigation">
                <div className="vrtx-fast-navigation-container">
                    <Link
                        to="/home/insurance#fundament"
                        className={`vrtx-fast-navigation-box`}
                        onMouseEnter={(event) => {this.handleMouseEnter(event)}}
                        onMouseLeave={(event) => {this.handleMouseLeave(event)}}
                    >
                        <div>Медицинское <br className="vrtx-fast-navigation-disappear"/> страхование</div>
                    </Link>
                    <Link
                        to="/home/nostrification#procedure"
                        className={`vrtx-fast-navigation-box ${active ? "vrtx-fast-navigation-box-active" : ""}`}
                    >
                        <div>Нострификация <br className="vrtx-fast-navigation-disappear"/> аттестата</div>
                    </Link>
                    <Link
                        to="/home/visa"
                        className={`vrtx-fast-navigation-box`}
                        onMouseEnter={(event) => {this.handleMouseEnter(event)}}
                        onMouseLeave={(event) => {this.handleMouseLeave(event)}}
                    >
                        <div>Виза</div>
                    </Link>
                    <Link
                        to="/home/useful#network"
                        className={`vrtx-fast-navigation-box`}
                        onMouseEnter={(event) => {this.handleMouseEnter(event)}}
                        onMouseLeave={(event) => {this.handleMouseLeave(event)}}
                    >
                        <div>Пригодится <br className="vrtx-fast-navigation-disappear"/> в быту</div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default FastNavigation;