import React, {Component} from "react";
import "./photo-and-text.css"
import "./photo-and-text-mobile.css"

class PhotoAndText extends Component {
    render() {
        const {text} = this.props;
        return (
            <div className={"photo-and-text"}>
                <div className={"photo-and-text-content"}>
                    {text}
                </div>
            </div>
        );
    }
}

export default PhotoAndText;