import React, {Component} from "react";
import "./above-navbar.css"
import "./above-navbar-mobile.css"
import logo from "../../img/logo.png"
import {Link} from "react-router-dom";
import {ReactComponent as Instagram} from "./instagram.svg";

class AboveNavbar extends Component {
    render() {
        return (
            <div className={"above-navbar"}>
                <div className={"above-navbar-container"}>
                    <Link to="/home">
                        <img src={logo} alt="vertexschool logo"/>
                    </Link>
                    <p><b>Языковые курсы при Техническом Университете в Праге</b></p>
                    <Link
                        className={"email"}
                        to={"#"}
                        onClick={(e) => {
                            window.location = "mailto:info@vertexschool.cz";
                            e.preventDefault();
                        }}
                    >
                        info@vertexschool.cz
                    </Link>
                    <a href={"https://www.instagram.com/vertex_school/"} target="_blank">
                        <Instagram className={"icon"}/>
                    </a>
                </div>
            </div>
        );
    }
}

export default AboveNavbar;