import React, {Component} from "react";
import "./base-under-header.css"
import "./base-under-header-mobile.css"
import "../../animations.css"
import VrtxSwitch from "./VrtxSwitch/VrtxSwitch";
import Path from "../Path/Path";

class BaseUnderHeader extends Component {
    render() {
        const {title, className} = this.props;
        return (
            <div className={`base-under-header ${className}`}>
                <VrtxSwitch
                    title={title}
                />
                <Path/>
            </div>
        )
    }
}

export default BaseUnderHeader;