import React, {Component} from "react";
import "./text.css"
import "./text-mobile.css"

class Text extends Component {
    render() {
        const {text, className, style} = this.props;
        return (
            <div
                className={`base-text ${className}`}
                style={style}
            >
                {text}
            </div>
        )
    }
}

export default Text;