import React, {Component} from "react";
import {Link} from "react-router-dom";

import "./6categories.css"
import "./6categories-mobile.css"

import block11 from "./resources.png"
import block12 from "./history.jpg"
import block21 from "./ctu.jpg"
import block22 from "./bank.jpg"
import block23 from "../../Useful/operator.jpg"

import {ReactComponent as Arrow} from "./arrow-right.svg";

class Categories6 extends Component {
    render() {
        return (
            <div className={"categories6"}>
                <div className="categories6-container">
                    <Link className="block11 block" to={"/home/categories#resource"}>
                        <img src={block11} alt="vertexschool categories 11"/>
                        <div className={"overlay-green"}/>
                        <div className={"block-content"}>
                            <h2>Исследования</h2>
                        </div>
                    </Link>
                    <Link className="block12 block" to={"/home/history"}>
                        <img src={block12} alt="vertexschool categories 12"/>
                        <div className={"block-content"}>
                            <h2>История</h2>
                        </div>
                    </Link>
                    <div className="block13 block">
                        <div className={"block-content"}>
                            <h1>Почему ЧТУ</h1>
                            <p>
                                В Чешском техническом университете в Праге можно проходить курс обучения во всех составных частях структуры высшего технического образования.
                                Это трехлетний и четырехлетний бакалавриат, магистерские специальности, а также трехлетние или четырехлетние докторские программы.
                            </p>
                            <Link to={"/home/categories#whyctu"}>
                                <Arrow className={"icon"}/>
                            </Link>
                        </div>
                    </div>
                    <Link className="block21 block" to={"/home/categories#ctu"}>
                        <img src={block21} alt="vertexschool categories 21"/>
                        <div className={"overlay-blue"}/>
                        <div className={"block-content"}>
                            <h1>ЧТУ</h1>
                            <p>
                                Чешский технический университет в Праге - один из крупнейших и старейших технических университетов Европы.
                            </p>
                        </div>
                    </Link>
                    <Link className="block22 block" to={"/home/bank"}>
                        <img src={block22} alt="vertexschool categories 22"/>
                        <div className={"overlay-green"}/>
                        <div className={"block-content"}>
                            <h2>Студенческий счёт</h2>
                        </div>
                    </Link>
                    <Link className="block23 block" to={"/home/useful#network"}>
                        <img src={block23} alt="vertexschool categories 23"/>
                        <div className={"block-content"}>
                            <h2>Связь</h2>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Categories6;