import React, {Component} from "react";

import "./text-with-background.css"
import "./text-with-background-mobile.css"
import {ReactComponent as QuoteRight} from "./quote-right.svg";

class TextWithBackground extends Component {
    render() {
        const {text} = this.props;
        return (
            <div className="text-with-background">
                <QuoteRight className={"icon"}/>
                {text}
            </div>
        )
    }
}

export default TextWithBackground;