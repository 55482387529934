import {Component} from "react";
import "./triple-photo.css"
import "./triple-photo-mobile.css"

class TriplePhoto extends Component {
    render() {
        const {left, middle, right, style} = this.props;
        return (
            <div className={"triple-photo"} style={style}>
                <div className={"triple-photo-content"}>
                    <img src={left} alt="vertexschool trio photo left"/>
                    <img src={middle} alt="vertexschool trio photo middle"/>
                    <img src={right} alt="vertexschool trio photo right"/>
                </div>
            </div>
        );
    }
}
export default TriplePhoto;