import React, {Component} from 'react';
import "./base-content-slider.css"
import "./base-content-slider-mobile.css"

class BaseContentSlider extends Component {

    render() {
        const { items, current, className, elementsClassName, handleTouchStart, handleTouchMove, style } = this.props;
        return (
            <div
                className={`base-content-slider-wrap ${className}`}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                style={style}
            >
                <div
                    className={`base-content-slider-elements ${elementsClassName}`}
                    style={{transform: `translateX(calc(-1 * ${current} * 100% - ${current} * 30px))`}}
                >
                    {
                        items.map((Item) => (
                            Item
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default BaseContentSlider;