import React, {Component} from "react";
import {Link} from "react-router-dom";
import logo from "../../img/logo.png";

import "./form-footer.css"
import "./form-footer-mobile.css"
import {LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha} from "react-simple-captcha";
import emailjs from "emailjs-com";
import {ReactComponent} from "./times.svg";
import Loader from "../Loader/Loader";

class FormFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phone: "",
            message: "",
            captcha: "",
            nameError: false,
            phoneError: false,
            captchaError: false,
            response: null,
            isLoading: false
        }
        this.doSubmit = this.doSubmit.bind(this);
    }

    componentDidMount () {
        loadCaptchaEnginge(6);
    };

    handleChange = (event) => {
        event.preventDefault();
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state[event.target.name] = event.target.value
    }

    handleName = (event) => {
        event.preventDefault();
        this.setState({name: event.target.value, nameError: false})
    }

    handleCaptcha = (event) => {
        event.preventDefault();
        this.setState({captcha: event.target.value, captchaError: false})
    }

    handleSelect = (event) => {
        this.setState({coursesError: false, courses: event.target.value})
    }
    handlePhone = (event) => {
        let value = event.target.value;
        if (/^(\+)?([ 0-9]){9,16}$/g.test(value) || !Boolean(value)) {
            this.setState({phone: value, phoneError: false})
        } else {
            this.setState({phoneError: true})
        }
    }

    validate = () => {
        const {phone, name} = this.state;
        let phoneError = !(Boolean(phone) && phone !== "");
        let nameError = !(Boolean(name) && name !== "");
        this.setState({
            phoneError: phoneError,
            nameError: nameError,
        })
        return !phoneError && !nameError;
    }

    doSubmit = () => {
        const {captcha} = this.state;
        if (validateCaptcha(captcha, false)) {
            const valid = this.validate();
            if (valid) {
                this.sendEmail(this.state);
            }
            this.setState({captchaError: false})
        }
        else {
            this.setState({captchaError: true})
            loadCaptchaEnginge(6);
        }
    };

    sendEmail = (data) => {
        this.setState({isLoading: true})
        let serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        let templateId = process.env.REACT_APP_EMAILJS_CONTACT_FORM_TEMPLATE_ID;
        let userId = process.env.REACT_APP_EMAILJS_USER_ID;

        const formData = {
            name: data.name,
            phone: data.phone,
            message: data.message
        }
        emailjs.send(
            serviceId, templateId, formData, userId
        )
            .then((result) => {
                this.setState({response: {
                        message: "Спасибо! Ваша заявка принята в работу.",
                        color: "#19D5CE"
                    },
                    isLoading: false,
                    name: "",
                    phone: "",
                    message: "",
                    captcha: ""
                })
            }, (error) => {
                this.setState({response: {
                        message: "Что то пошло не так попробуйте позже.",
                        color: "#F55C47"
                    }, isLoading: false})
            });
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({response: null})
    }


    render() {
        const {name, phone, message, phoneError, captchaError, nameError, response, isLoading} = this.state;
        return (
            <footer className={"form-footer"}>
                <div className={"form-footer-content-wrap"}>
                    <div className={"form-footer-content"}>
                        <div className="form-footer-left">
                            <div className={"form-footer-left-wrap"}>
                                <Link to="/home">
                                    <img src={logo} alt="vertexschool logo"/>
                                </Link>
                                <p><b>Языковые курсы при Техническом Университете в Праге
                                    <br/>
                                    <br/>
                                    Чешская республика, Прага 6 - Дейвицка, Техническая 2
                                </b></p>
                            </div>
                        </div>
                        <div className="form-footer-right">
                            <h2>Хотите учиться с нами?</h2>
                            <div className={"form-footer-pair"}>
                                <div className="form-footer-input-box">
                                    <input
                                        type="text"
                                        placeholder="ФИО"
                                        name={"name"}
                                        onChange={this.handleName}
                                        defaultValue={name}
                                        style={{
                                            borderColor: nameError ? "#F55C47" : ""
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"form-footer-pair"}>
                                <div className={"form-footer-pair"}>
                                    <div className={"form-footer-column-pair"}>
                                        <div className="form-footer-input-box">
                                            <input
                                                type="phone"
                                                placeholder="Телефон"
                                                name={"phone"}
                                                onChange={this.handlePhone}
                                                defaultValue={phone}
                                                style={{
                                                    borderColor: phoneError ? "#F55C47" : ""
                                                }}
                                            />
                                        </div>
                                        <div className={"form-footer-pair"}>
                                            <div className={"form-footer-captcha-wrap"}>
                                                <LoadCanvasTemplateNoReload/>
                                            </div>
                                            <div className={`form-footer-captcha-input-box form-footer-input-box`}>
                                                <input
                                                    type="captcha"
                                                    name={"captcha"}
                                                    placeholder="Введите текст"
                                                    id={"user_captcha_input"}
                                                    onChange={this.handleCaptcha}
                                                    style={{
                                                        borderColor: captchaError ? "#F55C47" : ""
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer-input-box form-footer-message-box">
                                <textarea
                                    typeof="text"
                                    placeholder="Сообщение"
                                    name={"message"}
                                    onChange={this.handleChange}
                                    defaultValue={message}
                                />
                                </div>
                            </div>
                            {
                                isLoading ? <Loader/> :
                                    !Boolean(response) ?
                                        <button
                                            className="button application-button"
                                            onClick={this.doSubmit}
                                        >
                                            ОТПРАВИТЬ
                                        </button>
                                        :
                                        <div className={"response-message"} onClick={this.handleClose} style={{background: response.color}}>
                                            <ReactComponent className={"icon"}/>
                                            <p>
                                                {response.message}
                                            </p>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FormFooter;