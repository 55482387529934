import React, {Component} from 'react';
import "./useful.css"
import TitleAndTextWithBackground from "../../Template/TitleAndTextWithBackground/TitleAndTextWithBackground";
import TextWithBackground from "../../Template/TextWithBackground/TextWithBackground";
import Text from "../../Template/Text/Text";
import BaseStickyHeader from "../../Components/StickyHeader/BaseStickyHeader";
import BaseContentSlider from "../../Components/ContentSlider/BaseContentSlider";
import infr1 from "./inf1.1.jpg"
import infr2 from "./inf2.2.jpg"
import infr3 from "./inf3.3.jpg"

import operator from "./operator.jpg"
import operator1 from "./network1.jpg"
import operator2 from "./network2.jpg"

import useful from "./useful.jpg"

import transport from "./transport.jpg"
import tr1 from "./tr1.jpg"
import tr2 from "./tr2.jpg"
import tr3 from "./tr3.jpg"

import food from "./food.jpg"
import food1 from "./food1.jpg"
import food2 from "./food2.jpg"
import food3 from "./food3.jpg"

import TitleWithList from "../../Template/TitleWithList/TitleWithList";
import H2TitleWithTextAndPhoto from "../../Template/TitleWithTextAndPhoto/H2TitleWithTextAndPhoto";
import rent from "./rent.jpg";
import H3TwoTitlesWithTwoTexts from "../../Template/TwoTitlesWithTwoTexts/H3TwoTitlesWithTwoTexts";
import IconAndTextWithBackground from "../../Template/IconAndTextWithBackground/IconAndTextWithBackground";
import TriplePhoto from "../../Template/TriplePhoto/TriplePhoto";
import Path from "../../Components/Path/Path";

class Useful extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: "#network",
            items: [
                {name: "связь", to: "#network"},
                {name: "транспорт", to: "#transport"},
                {name: "Что с собой", to: "#important"},
                {name: "Питание", to: "#food"},
                {name: "Аренда", to: "#rent"}
            ]
        }
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
    }

    componentDidMount() {
        let hash = window.location.hash;
        if (Boolean(hash)) {
            this.setState({current: this.getCurrent(hash)})
        }
        window.scrollTo(0, 0)
    }


    getCurrent = (hash) => {
        const {items} = this.state;
        return items.findIndex(item => item.to === hash);
    }

    handleChange = (event, to) => {
        this.setState({currentHash: to, current: this.getCurrent(to)})
    }

    getTouches = (event) => {
        return event.touches ||
            event.originalEvent.touches;
    }

    handleNext = () => {
        const {current, items} = this.state;
        let next = current + 1;
        if (next > items.length - 1) {
            next = current;
        }
        this.setState({current: next})
    }

    handlePrev = () => {
        const {current} = this.state;
        let prev = current - 1;
        if (prev < 0) {
            prev = 0
        }
        this.setState({current: prev})
    }

    handleTouchStart = (event) => {
        const firstTouch = this.getTouches(event)[0];
        let xDown = firstTouch.clientX;
        let yDown = firstTouch.clientY;
        this.setState({xDown: xDown, yDown: yDown})
    };

    handleTouchMove = (event) => {
        const {xDown, yDown} = this.state;
        if ( ! xDown || ! yDown ) {
            return;
        }

        let xUp = event.touches[0].clientX;
        let yUp = event.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                this.handleNext();
            } else {
                this.handlePrev();
            }
        }
        /* reset values */
        this.setState({xDown: null, yDown: null})
    };

    render() {
        const {items, current} = this.state;
        return (
            <div className={"home-useful"} style={{marginTop: "0"}}>
                <BaseStickyHeader items={items} handleChange={this.handleChange}/>
                <Path/>
                <div className={"home-useful"}>
                    <BaseContentSlider
                        handleTouchStart={this.handleTouchStart}
                        handleTouchMove={this.handleTouchMove}
                        current={current}
                        items={[
                            (<div>
                                <H2TitleWithTextAndPhoto
                                    width={"50%"}
                                    title={"Мобильная связь в Чехии"}
                                    path={operator}
                                    text={<p>
                                        Мобильная связь, как и мобильный интернет в Европе, и в Чехии непосредственно дорогая. Ею надо научится пользоваться максимально рационально и с пользой. В Чехии существуют три основных оператора сотовой связи. Это Vodafone, T-Mobile, и О2. Принципиального различия в качестве связи и покрытия между ними нет. Но есть один параметр, о котором стоит подумать. Это цена звонка. Стоимость звонков у операторов различается. Традиционно считается самым дорогим оператором О2. Потом T-Mobile. И замыкает тройку Vodafone. Особенность связи заключается в том, что звонки к примеру внутри сети Vodafone составляет примерно 2-3 кроны за начатую минуту, а звонок абонента пользующегося оператором Vodafone к примеру абоненту пользующимся О2 может стоить уже 5-6 крон минута. Ну а так как традиционно сложилось, что в студенческой среде более популярен Vodafone, мы советуем нашим студентам обратить на этот факт внимание.
                                    </p>}
                                />
                                <TextWithBackground
                                    text={<p>
                                        <b style={{fontStyle: "italic"}}>
                                            Проверить количество средств на карте можно набрав бесплатный вызов на номер *22#. На экране появится сумма, оставшаяся на сим-карте.
                                        </b>
                                    </p>}
                                />
                                <Text
                                    text={<p>
                                        Ценовая политика постоянно меняются.
                                        Существуют всевозможные специальные предложения.
                                        Поощрения. Или тарифы, которые периодически появляются у операторов. Цены могут изменятся в зависимости от тенденций.
                                        После получения чешской сим-карты от нашей школы, на ней есть начальный кредит на звонки.
                                        Активация проходит автоматически, после включения телефона, и начала работы карты.
                                        Входящий звонок бесплатный.
                                        Как в Чехии так из-за рубежа. Исходящий звонок платный.
                                        Так что звонить ребятам мамам-папам наверное не стоит.
                                        Лучше звонить периодически своим чадам самим родителям, это сэкономит студентам немалые средства.
                                        Либо пользоваться звонками через интернет, благо у ребят подключение к Wi-Fi будет почти всегда.
                                        Пополнить денежную сумму на сим-карте, можно в любом киоске, заполнив специальный талон, где вписывается номер телефона и сумма пополняемого кредита.
                                        Либо купив специальную карточку оператора на необходимую сумму. Также карточки продаются во многих торговых центрах, либо небольших магазинчиках. Или непосредственно в отделениях оператора расположенных по городу.
                                    </p>}
                                />
                                <TriplePhoto
                                    style={{marginTop: "80px"}}
                                    left={operator}
                                    middle={operator1}
                                    right={operator2}
                                />
                            </div>),
                            (<div>
                                <H2TitleWithTextAndPhoto
                                    title={"Городской транспорт в Праге"}
                                    path={transport}
                                    width={"50%"}
                                    text={<p>
                                        В Праге одна из лучших систем общественного транспорта. Метро, трамвай, автобус — по ним можно сверять свои часы. Город обладает развитой сетью трамвайных и автобусных линий.
                                        Она покрывает большую часть города. Существуют трамвайные и автобусные маршруты, которые работают только ночью с 00:00 до 4:30 и соединяют центр с окраинами, покрывая практически весь город. В систему общественного транспорта Праги также включены участки пригородных железных дорог, позволяющие пользоваться едиными проездными документами. Билеты на проезд можно купить в автоматах на остановках и станциях метро, в газетных и табачных киосках или посредством СМС. В городе есть несколько видов билетов на городской транспорт, а также LÍTAČKA (это проездной-о нём мы расскажем и оформим нашим ребятам после приезда).

                                        Покупка билета, и его нахождение в вашем бумажнике или кармане, не означает что вы уже можете пользоваться транспортом, его надо обязательно прокомпостировать. Билеты различаются временем действия каждого билета в минутах от момента его пробития в специальных компостерах которые расположены в автобусах, трамваях, и перед входом в метро. Билет на 30 мин стоит 30 крон. На 90 минут 40 крон. На 24 часа 120 крон. На 72 часа 330 крон. Билеты на транспорт — единые. То есть от момента означивания билета в каком либо транспорте, у вас есть время пользоваться городским транспортом с пересадками, ровно до времени окончания его действия с момента его пробития. Любым транспортом, кроме такси. Означивать билет несколько раз не нужно. Это делается один раз. На самом билете после компостирования отпечатается время, от которого у вас начался обратный отсчёт действия билета.

                                    </p>}
                                />
                                <TitleAndTextWithBackground
                                    title={"SMS билет"}
                                    text={<p>
                                        <b style={{fontStyle: "italic"}}>
                                            Билет также можно приобрести через телефон, имея чешскую сим-карту. Для покупки достаточно на номер 902 06 отправить SMS сообщение с текстом «DPT30» либо «DPT40» и т.д. в зависимости от цены необходимого билета. Стоимость билета+стоимость смс сообщения спишут с вашего мобильного тарифа. Вам придёт сообщение где указана дата, а также время начала и окончания действия билета. В случае проверки в транспорте, достаточно показать SMS контроллеру.
                                        </b>
                                    </p>}
                                />
                                <TriplePhoto
                                    style={{marginTop: "80px"}}
                                    left={tr1}
                                    middle={tr2}
                                    right={tr3}
                                />
                            </div>),
                            (<div>
                                <H2TitleWithTextAndPhoto
                                    title={"Что с собой"}
                                    width={"50%"}
                                    path={useful}
                                    text={<p>
                                        Первые дни, недели, для наших студентов являются самыми сложными в плане адаптации жизни в Праге. Это и транспорт, и его организация в столице. Магазины и супермаркеты, их специфика их работы. Это мобильная связь и понимание работы мобильных сетей в Чехии. И так далее, и так далее… Мы всячески поможем ребятам сориентироваться в этих вопросах. Но то, на что мы повлиять не можем, это с каким багажом они приедут из родной страны :). Часты вопросы родителей, касающихся быта, и первых дней студентов. Позвольте нам несколько дружеских советов, и надеемся что они вам пригодятся и будут полезны.
                                    </p>}
                                />
                                <Text
                                    style={{
                                        marginTop: "10px"
                                    }}
                                    text={<p>
                                        Далее, необходимую сумму, покрывающую первый месяц-два его нахождения в Праге. С учётом расходов на проживание, питания, сотовой связи. Везти все деньги наличностью нет никакой необходимости, хватит 200-300 долларов. Всё остальное может быть на банковской карте.
                                        Так как наши курсы предлагают проживание в общежитиях ВУЗа, мелкие столовые приборы и принадлежности в первые дни и недели также не помешают. Вилка ложка, тарелка, кружка? Конечно :). Иногда может быть и сковорода (упоминание о ней меня отдельно попросили оставить:)) Подушки и одеяло? Не надо, его выдают в общежитии, так же как и постельное бельё. Всё необходимые более крупные предметы, либо электроприборы ребята могут купить уже здесь, по мере необходимости.
                                        Дорогие студенты и их родители. Это основные пункты, на которые вам необходимо обратить внимание. Ну а с чем приедут ребята, все же решать вам. Мы будем рады им с любым багажом 🙂
                                    </p>}
                                />
                                <TextWithBackground
                                    text={<p>
                                        <b style={{fontStyle: "italic"}}>
                                            Курсы начинаются осенью. В Праге уже может быть холодно. Одежда по сезону. Ноутбук? Он тоже не помешает. Интернет в учёбе будет необходим.
                                        </b>
                                    </p>}
                                />
                                <TitleWithList
                                    title={"Документы. Студент обязан иметь с собой:"}
                                    items={[
                                        "Полис медицинского страхования на срок обучения(если он был выслан)",
                                        "Подтверждение о проживании в указанном общежитии Университета.",
                                        "5-6 фотографий паспортного образца.",
                                        "а также аттестат о законченном среднем образовании с оценками и перечнем предметов за последние 3 года. Более подробная информация в разделах о нострификации.\n"
                                    ]}
                                />
                                <TriplePhoto
                                    style={{marginTop: "80px"}}
                                    left={infr1}
                                    middle={infr2}
                                    right={infr3}
                                />
                            </div>),
                            (<div>
                                <H2TitleWithTextAndPhoto
                                    width={"50%"}
                                    path={food}
                                    title={"Питание. Где покушать, как и сколько это стоит"}
                                    text={<p>
                                        Студенты живущие в наших общежития могут готовить самостоятельно в кухнях находящихся в общежитиях, которые оборудованы всем необходимым. Также университет обладает сетью студенческих столовых и буфетов, которые находятся или непосредственно на территории ВУЗа, или в самих общежитиях. Практически в любом из них вас накормят. Цены на питание в среднем варьируются за первое и второе блюдо с напитком от 4 до 6 евро.
                                    </p>}
                                />
                                <Text
                                    style={{
                                        marginTop: "-25px"
                                    }}
                                    text={<p>
                                        Время работы у всех заведений разное. У каждой столовой или буфета, находится ли они при общежитии, или на территории ВУЗа, есть свой график открытия и закрытия. Где то столовые работают с 08.00 утра, где то с 11.00. Также варьируется и окончание работы. Полную информацию о графику работы всегда можно получить в самом заведении, они как правило указаны на входных дверях.
                                        Также студенты могут питаться в городе. Прага предлагает широкий выбор заведений быстрого питания, закусочных, столовых, пиццерий и ресторанчиков. Цены везде разные, в зависимости от типа заведения, а также его местоположения. В центре города это всегда будет дороже. У студентов всегда есть выбор, и как правило ребята со временем достаточно быстро определяются как по ценам, так и в предлагаемом рационе. Цены в среднем варьируются за блюдо от 5 до 7 евро.
                                    </p>}
                                />
                                <TriplePhoto
                                    style={{marginTop: "80px"}}
                                    left={food1}
                                    middle={food2}
                                    right={food3}
                                />
                            </div>),
                            (<div>
                                <H2TitleWithTextAndPhoto
                                    title={""}
                                    width={"50%"}
                                    text={<p>
                                        Условия проживания и быта в разных общежитиях Университета разнятся. Если условия быта не отвечают представлениям родителей или студентов, то аренда квартиры в Праге, может быть решением вопроса проживания.
                                        Если вы решили снять квартиру в аренду, тогда вам необходимо позаботится об этом заранее. Пока вы подберёте устраивающий вас вариант, на это может уйти примерно месяц-два.
                                        Общая стоимость аренды формируется и зависит от нескольких факторов. Таких как, местонахождение квартиры, ее величина, тип дома в котором она находится (кирпичный дом или панельный), состояние квартиры на момент сдачи в аренду, обставлена ли квартира мебелью или нет, и конечно же размер коммунальных платежей в месяц (poplatky), которые плюсуются к стоимости самой аренды в месяц. Квартиры делятся по планировке на +1 или КК. Где +1, это отдельная кухня. То есть полноценные комнаты, и полноценная кухня, находящаяся в отдельной комнате. «КК-кухонный коут» это кухня, установленная в одной из комнат, иногда отделанная или барной перегородкой, или установленная нише-аппендиксе. Как правило, квартиры +1 дороже, так как они больше по метражу и элементарно удобнее.
                                    </p>}
                                    path={rent}
                                />
                                <IconAndTextWithBackground
                                    text={<p>
                                        <b style={{fontStyle: "italic"}}>
                                            Поплатки — коммунальные платежи. Куда входят оплата за израсходованный газ, электричество, воду. Как раз именно эти платежи в последнее время собственники жилья стали оформлять на арендаторов. По принципу, „сколько израсходовал-столько и заплатишь“. Поэтому схема аренды примерно выглядит так: аренда + ком. платежи + вода, газ или электричество
                                        </b>
                                    </p>}
                                />
                                <H3TwoTitlesWithTwoTexts
                                    leftTitle={"Kauce - Залог"}
                                    rightTitle={"Агентства Недвижимости"}
                                    leftText={<p>
                                        При аренде квартиры, вместе с первым месяцем аренды оплачивается залог (Kauce). Он как правило возвращаемый после окончания срока аренды, который служит гарантией соблюдения условий договора и возвращается по окончанию срока проживания, при отсутствии претензий по коммунальным платежам, и состоянию движимого имущества (мебель, бытовые приборы и т.п.). Залог возвращается в день окончания аренды, либо отдельно оговоренные сроки.
                                    </p>}
                                    rightText={<p>
                                        Как правило в Праге аренда квартир происходит через агентства. Они вам помогут подобрать квартиру из своей базы данных, или компаний партнёров. В этом случае вам надо считаться с вознаграждением для компании, от которой вы выберете квартиру. Если вы обратитесь к чешской компании, оплата вознаграждения обычно составляет 100% стоимости аренды в месте с ком. платежами. Но также можно встретить компании у которых это может стоить 50% от стоимости аренды.
                                    </p>}
                                />
                                <IconAndTextWithBackground
                                    text={<p>
                                        <b style={{fontStyle: "italic"}}>
                                            Также вы можете это сделать самостоятельно, и выбрать квартиру напрямую от собственников. Но в этом случае вам надо быть особенно на стороже, и обращать внимание на все аспекты аренды. Особенно при прочтении договора аренды.
                                        </b>
                                    </p>}
                                />
                            </div>)
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Useful;