import React, {Component} from 'react';

import "./home-header-top.css"
import "./home-header-top-mobile.css"
import "../../../../animations.css"
import bg from "./main-header.jpg"
import {Link} from 'react-router-dom';

class HomeHeaderTop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "Курсы чешского языка в Праге",
            text: <b>Годовые языковые курсы при Чешском Техническом Университете в Праге</b>,
            buttonName: "НАШИ ПРОГРАММЫ"
        }
    }

    render() {
        const {title, text, buttonName } = this.state;
        return (
            <div className="home-header-top-container">
                <img src={bg} alt="vertexschool home page" className={"scaleFrom0"}/>
                <div className="home-header-top-title-wrap slideInFromLeft">
                    <div className="home-header-top-title">
                        <h1>{title}</h1>
                        <p>{text}</p>
                        <div className="wrap">
                            <Link className="button" to={"/home/programs#fundament"}>{buttonName}</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeHeaderTop;