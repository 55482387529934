import React, {Component} from 'react';
import "./accommodation-item.css"
import "./accommodation-item-mobile.css"
import {ReactComponent as MapMarker} from "./map-marker.svg";
import H2TitleWithText from "../../Template/TitleWithText/H2TitleWithText";

class AccommodationItem extends Component {


    render() {
        const {item} = this.props

        return (
            <div className="accommodation-item">
                <div className={"accommodation-item-left"}>
                    <H2TitleWithText
                        title={item.topLeft.title}
                        text={item.topLeft.text}
                        className={"custom-title-with-text"}
                    />
                    <div className={"accommodation-item-left-bottom"}>
                        <h3>{item.bottomLeft.title}:</h3>
                        <ul>
                            {
                                item.bottomLeft.items.map(txt => (
                                    <li>{txt}</li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className={"accommodation-item-right"}>
                    <img src={item.right.path} alt={`vertexschool accommodation ${item.hash}`}/>
                    <div className={"accommodation-item-right-address"}>
                        <MapMarker className={"icon"}/>
                        <div>{item.right.title}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccommodationItem;