import React, {Component} from "react";
import "./base-title-with-text.css"
import "./title-with-text-mobile.css"

class H2TitleWithText extends Component {
    render() {
        const {title, text, className, minWidth, marginTop} = this.props;
        let titleArray = title.split(" ");
        let title1 = titleArray.slice(0, titleArray.length - 1);
        let title2 = titleArray[titleArray.length - 1]
        return (
            <div
                className={`base-title-with-text ${className}`}
                style={{
                    minWidth: Boolean(minWidth) ? minWidth : "none",
                    marginTop: Boolean(marginTop) ? marginTop : "40px"
                }}
            >
                {
                    Boolean(title) && <div className={"base-title-with-text-title"}>
                        <h2>
                            {Boolean(title1) ? (title1.join(" ") + String.fromCharCode(160)) : ""} <span>{`${title2}`}</span>
                        </h2>
                    </div>
                }
                {text}
            </div>
        )
    }
}

export default H2TitleWithText;