import React, {Component} from "react";
import "./base-title-with-text.css"
import "./title-with-text-mobile.css"

class H3TitleWithText extends Component {
    render() {
        const {title, text, className, fit, marginTop, minWidth} = this.props;
        let titleArray = title.split(" ");
        let title1 = titleArray.slice(0, titleArray.length - 1).join(" ");
        let title2 = titleArray[titleArray.length - 1]
        return (
            <div
                className={`base-title-with-text ${className}`}
                style={{
                    height: Boolean(fit) ? "fit-content" : "100%",
                    marginTop: Boolean(marginTop) ? marginTop : "40px",
                    minWidth: Boolean(minWidth) ? minWidth : "none"
                }}
            >
                {
                    Boolean(title) && <div className={"base-title-with-text-title"}>
                        <h3>
                            {Boolean(title1) ? (title1 + String.fromCharCode(160)) : ""} <span>{`${title2}`}</span>
                        </h3>
                    </div>
                }
                {text}
            </div>
        )
    }
}

export default H3TitleWithText;