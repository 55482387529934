import React, {Component} from "react";
import "./two-titles-with-two-lists.css"
import "./two-titles-with-two-lists-mobile.css"

class H3TwoTitlesWithTwoLists extends Component {
    render() {
        const {leftTitle, leftItems, rightTitle, rightItems, className, style} = this.props;
        let leftTitleArray = leftTitle.split(" ");
        let leftTitle1 = leftTitleArray.slice(0, leftTitleArray.length - 1);
        let leftTitle2 = leftTitleArray[leftTitleArray.length - 1]
        let rightTitleArray = rightTitle.split(" ");
        let rightTitle1 = rightTitleArray.slice(0, rightTitleArray.length - 1);
        let rightTitle2 = rightTitleArray[rightTitleArray.length - 1]
        return (
            <div className={`base-wo-titles-with-two-lists ${className}`} style={style}>
                <div className={"base-wo-titles-with-two-lists-left"}>
                    <div className={"base-wo-titles-with-two-lists-title"}>
                        <h3>
                            {leftTitle1.join(" ") + String.fromCharCode(160)} <span>{`${leftTitle2}`}</span>
                        </h3>
                    </div>
                    <div className={"base-wo-titles-with-two-lists-list"}>
                        <ul>
                            {
                                leftItems.map(item => (
                                    <li><span>•</span><div>{item}</div></li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className={"base-wo-titles-with-two-lists-right"}>
                    <div className={"base-wo-titles-with-two-lists-title"}>
                        <h3>
                            {rightTitle1.join(" ") + String.fromCharCode(160)} <span>{`${rightTitle2}`}</span>
                        </h3>
                    </div>
                    <div className={"base-wo-titles-with-two-lists-list"}>
                        <ul>
                            {
                                rightItems.map(item => (
                                    <li><span>•</span><div>{item}</div></li>
                                ))
                            }
                        </ul>
                    </div>
                </div>


            </div>
        )
    }
}

export default H3TwoTitlesWithTwoLists;