import {Component} from "react";
import "./path.css"
import "./path-mobile.css"
import {Link} from "react-router-dom";
import {ReactComponent as Right} from "./right.svg";

class Path extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dictionary: {
                "home": "Главная",
                "about-us": "О нас",
                "categories": "ЧТУ",
                "programs": "Программы",
                "accommodation" : "ПРОЖИВАНИЕ",
                "nostrification": "НОСТРИФИКАЦИЯ",
                "insurance": "МЕД. СТРАХОВАНИЕ",
                "visa": "Виза",
                "useful": "пригодится",
                "contacts" : "Контакты",
                "history": "История",
                "bank": "банк"
            }
        }
    }

    getTo = (links, index) => {
        let result = "";
        for (let i = 0; i <= index; i ++) {
            result += `/${links[i]}`
        }
        return result;
    }

    parsePath = (path) => {
        const {dictionary} = this.state;
        let arr = path.split("/")
        const links = arr.filter((a) => a);
        let result = [];
        for (let i = 0; i < links.length; i++) {
            let translate = dictionary[links[i]];
            if (Boolean(translate)) {
                result.push({name: translate, to: this.getTo(links, i)})
            }
        }
        return result;
    }
    render() {
        const {style} = this.props;
        let path = window.location.pathname;
        if (!Boolean(path)) {
            return;
        }
        const items = this.parsePath(path);
        const lastIndex = items.length - 1;
        return (
            <div
                className={"vrtx-path"}
                style={style}
            >
                {
                    items.map((item, index) => {
                        return<div className={"vrtx-path-item"}>
                            <Link to={item.to}>{item.name}</Link>
                            {
                                lastIndex !== index && <Right className={"icon"}/>
                            }
                        </div>
                    })
                }
            </div>
        );
    }
}

export default Path;