import {Component} from "react";
import "./sticky-header-title.css"
import "./sticky-header-title-mobile.css"

class StickyHeaderTitle extends Component {
    render() {
        const {title} = this.props;
        return (
            <div className={"sticky-header-title"}>
                <h2>{title}</h2>
            </div>
        );
    }
}

export default StickyHeaderTitle;