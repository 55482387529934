import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import Main from "./Main/Main";
import Categories from "./Categories/Categories";
import Insurance from "./Insurance/Insurance";
import Visa from "./Visa/Visa";
import Nostrification from "./Nostrification/Nostrification";
import Useful from "./Useful/Useful";
import History from "./History/History";
import Bank from "./Bank/Bank";

class Home extends Component {
    render() {
        const {match} = this.props;
        return (
            <Switch>
                <Route path={match.url} exact={true} component={Main} />
                <Route path={`${match.url}/categories`} exact={true} component={Categories}/>
                <Route path={`${match.url}/history`} exact={true} component={History}/>
                <Route path={`${match.url}/insurance`}  exact={true} component={Insurance}/>
                <Route path={`${match.url}/visa`}  exact={true} component={Visa}/>
                <Route path={`${match.url}/nostrification`}  exact={true} component={Nostrification}/>
                <Route path={`${match.url}/useful`} exact={true} component={Useful}/>
                <Route path={`${match.url}/bank`} exact={true} component={Bank}/>
            </Switch>
        );
    }
}
export default Home
