import React, {Component} from 'react';

import "./slim-header.css"
import "./slim-header-mobile.css"
import "../../animations.css"

class SlimHeader extends Component {
    render() {
        const {title, text, url} = this.props;
        return (
            <header className="slim-base-header">
                {
                    Boolean(url) && <img src={url} alt={`vertexschool ${title}`} className={"vrtx-base-header-bg scaleFrom0"}/>
                }
                <div className={"slim-base-header-wrap"}>
                    <div className="slim-base-header-content slideInFromLeft">
                        <h1>{title}</h1>
                        {text}
                    </div>
                </div>
            </header>
        );
    }
}

export default SlimHeader;