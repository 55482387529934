import React, {Component} from 'react';
import "./programs-mobile.css"
import "./programs.css"
import "../animations.css"
import TextWithBackground from "../Template/TextWithBackground/TextWithBackground";
import H2TitleWithTwoTexts from "../Template/TitleWithTwoTexts/H2TitleWithTwoTexts";
import BaseStickyHeader from "../Components/StickyHeader/BaseStickyHeader";
import BaseContentSlider from "../Components/ContentSlider/BaseContentSlider";
import H3TwoTitlesWithTwoLists from "../Template/TwoTitlesWithTwoLists/H3TwoTitlesWithTwoLists";
import ListWithBackground from "../Template/ListWithBackground/ListWithBackground";

import programsHeader from "./programs-bg.jpg";
import programsText from "./programs-text.jpg";
import prg1 from "./prg1.1.jpg"
import prg2 from "./prg2.2.jpg"
import prg3 from "./prg3.3.jpg"

import TitleWithTextAndPhoto from "../Template/TitleWithTextAndPhoto/H2TitleWithTextAndPhoto";
import H2TitleWithText from "../Template/TitleWithText/H2TitleWithText";
import TriplePhoto from "../Template/TriplePhoto/TriplePhoto";
import StickyHeaderTitle from "../Components/StickyHeaderTitle/StickyHeaderTitle";
import BaseUnderHeader from "../Components/UnderHeader/BaseUnderHeader";
import AdvHeader from "../Components/AdvHeader/AdvHeader";

class Programs extends Component {
    constructor(props) {
        super(props);
        let today = new Date();
        this.state = {
            year: today.getFullYear(),
            stickyItems: [
                {name: "Фундамент", to: "#fundament"},
                {name: "Максимум", to: "#maximum"},
                {name: "Online плюс", to: "#online+"},
                {name: "Магистр", to: "#master"}
            ],
            current: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.setStateCustom = this.setStateCustom.bind(this);
    }

    setStateCustom = (state) => {
        this.setState(state)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    getCurrent = (hash) => {
        const {stickyItems} = this.state;
        return stickyItems.findIndex(item => item.to === hash);
    }

    handleChange = (event, to) => {
        this.setState({current: this.getCurrent(to)})
    }

    getTouches = (event) => {
        return event.touches ||
            event.originalEvent.touches;
    }

    handleTouchStart = (event) => {
        const firstTouch = this.getTouches(event)[0];
        let xDown = firstTouch.clientX;
        let yDown = firstTouch.clientY;
        this.setState({xDown: xDown, yDown: yDown})
    };

    handleTouchMove = (event) => {
        const {xDown, yDown, current, stickyItems} = this.state;
        if ( ! xDown || ! yDown ) {
            return;
        }

        let xUp = event.touches[0].clientX;
        let yUp = event.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                let next = current + 1;
                if (next > stickyItems.length - 1) {
                    next = stickyItems.length - 1;
                }
                window.location.hash = stickyItems[next].to;
                this.setState({current: next})
            } else {
                let prev = current - 1;
                if (prev < 0) {
                    prev = 0;
                }
                window.location.hash = stickyItems[prev].to;
                this.setState({current: prev})
            }
        }
        /* reset values */
        this.setState({xDown: null, yDown: null})
    };

    render() {
        const {stickyItems, current, year} = this.state;

        const Foundation =
            (<div>
                <H2TitleWithTwoTexts
                    title={"Годовая программа Фундамент"}
                    leftText={<p>
                        Годовая программа чешского языка с подготовительными предметами по своей структуре и методике полностью подходит абитуриентам поступающим в Технический университет в  Праге, в университеты страны с экономическим уклоном, а также  в гуманитарные университеты. Курс разделён на несколько этапов, что поможет студентам лучше усвоить программу обучения, без дополнительной нагрузки. Обучение ведут опытные преподаватели Университета с многолетним стажем работы. Программа рассчитана на нулевое знание абитуриентом чешского языка на начальном этапе его обучения. В стоимость программы также входят все учебные материалы.
                    </p>}
                    rightText={<p>
                        А также пособия которые будут необходимы студентам в процессе обучения. Они будут выданы учащимся в первый день обучения в аудитории университета. Наши курсы отличаются усиленным контролем успеваемости и посещением занятий. Предусмотрены тесты и контрольные работы, после которых проводится анализ и обсуждение ошибок, помощь студентам в их исправлении. После окончания зимнего семестра сдаётся зачёт, после окончания годового курса студенты сдают выпускной экзамен в присутствии комиссии и на основании его успешной сдачи получают сертификат, который подтверждает его уровень знания чешского языка B2. Этот документ предоставляется при поступлении в высшие учебные заведения страны.
                    </p>}
                />
                <H3TwoTitlesWithTwoLists
                    leftTitle={"Программа и стоимость"}
                    rightTitle={"В стоимость также включено"}
                    leftItems={[
                        <div>Стоимость программы: <strike>5400 EUR</strike> - при записи до <br/> мая {year} года - <b>4950 EUR</b></div>,
                        `Начало программы: сентябрь ${year}, окончание: июнь ${year + 1}`,
                        "Начальные знания чешского языка студентов: нулевое",
                        "Достигнутый уровень знания студента B2",
                        "Где: Чешский технический университет в Праге",
                        <b style={{fontStyle: "italic"}}>Учебная программа включает в себя:</b>,
                        "Чешский язык 770 часов",
                        "Программа подготовки к нострификации школьного аттестата по основным предметам 120 часов",
                        "Программа подготовки к поступлению в ВУЗы Чешкой республики 50 часов",
                        "Полную процедуру продления студентческой визы",
                        "Конроль качества обучения, контроль успеваемости и посещений занятий",
                        "Поддержка кураторами курсов на всем протяжении обучения"
                    ]}
                    rightItems={[
                        "Подготовка пакета документов к получению студенческой визы",
                        "Первичная регистрация в полиции для иностранцев",
                        "Встреча по приезду и трансфер до места проживания",
                        "Помощь в подготовке документов к нострификации школьного аттестата",
                        "Подача документов на нострификацию",
                        "После приезда всех студентов в Прагу, познавательные и информативные экскурсии по Праге",
                        "Консультации и оформление медицинского страхования (страхование оплачивается отдельно)",
                        "Предоставление сим-карты мобильного оператора с первичным балансом"
                    ]}
                />
                <ListWithBackground
                    items={[
                        <b>Экзаменационный период за первое полугодие 15.12.{year} — 22.12.{year + 1}.</b>,
                        <b>Зимние каникулы 22.12.{year} — 11.01.{year + 1}</b>,
                        <b>Экзаменационный период за второе полугодие 20.06.{year} — 30.06.{year + 1}</b>,
                        <b>Максимальное количество в группе 16 человек</b>,
                        <b>В стоимость программы также входят все учебные материалы и пособия которые будут необходимы студентам в процессе обучения. Они будут выданы учащимся в первый день обучения в аудитории университета</b>
                    ]}
                />
            </div>)
        const Maximum =
            (<div >
                <H2TitleWithTwoTexts
                    title={"Годовая программа Максимум"}
                    leftText={<p>
                        Годовая программа чешского языка «Максимум» отличаются усиленной часовой нагрузкой. Это более 5 академических часов обучения чешскому языку в день, пять дней в неделю. Высокие требования университетов к знанию чешского языка при поступлении, а также часто экзамен уровня знания языка, который может быть частью поступления в ВУЗ, предполагают устойчивое знание чешского языка не на словах, а на деле.
                    </p>}
                    rightText={<p>
                        Также часто первый год обучения будет не простым. Поступить при должном уровне подготовки, часто не так сложно как кажется, или как пугают "знатоки". Но вот выдержать первый год учебы в Университете, это часто испытание посерьезнее чем экзамен. Особенно в престижных ВУЗах, таких как ЧТУ в Праге, Карлов Университет, и т.д. Почти всегда, это связанно именно со знанием и пониманием языка, и то, где и как ты его учил.
                    </p>}
                />
                <H3TwoTitlesWithTwoLists
                    leftTitle={"Программа и стоимость"}
                    rightTitle={"В стоимость также включено"}
                    leftItems={[
                        "Стоимость программы 5700 EUR",
                        `Начало программы: сентябрь ${year}, окончание: июнь ${year + 1}`,
                        "Начальные знания чешского языка студентов: нулевое",
                        "Достигнутый уровень знания студента B2",
                        "Где: Чешский технический университет в Праге",
                        <b style={{fontStyle: "italic"}}>Учебная программа включает в себя:</b>,
                        "Чешский язык 890 часов",
                        "Программа подготовки к нострификации школьного аттестата по основным предметам 120 часов",
                        "Программа подготовки к поступлению в ВУЗы Чешкой республики 50 часов",
                        "Полную процедуру продления студентческой визы",
                        "Конроль качества обучения, контроль успеваемости и посещений занятий",
                        "Поддержка кураторами курсов на всем протяжении обучения"
                    ]}
                    rightItems={[
                        "Подготовка пакета документов к получению студенческой визы",
                        "Первичная регистрация в полиции для иностранцев",
                        "Встреча по приезду и трансфер до места проживания",
                        "Помощь в подготовке документов к нострификации школьного аттестата",
                        "Подача документов на нострификацию",
                        "После приезда всех студентов в Прагу, познавательные и информативные экскурсии по Праге",
                        "Консультации и оформление медицинского страхования (страхование оплачивается отдельно)",
                        "Предоставление сим-карты мобильного оператора с первичным балансом"
                    ]}
                />
                <ListWithBackground
                    items={[
                        <b>Экзаменационный период за первое полугодие 15.12.{year} — 22.12.{year + 1}.</b>,
                        <b>Зимние каникулы 22.12.{year} — 11.01.{year + 1}</b>,
                        <b>Экзаменационный период за второе полугодие 20.06.{year} — 30.06.{year + 1}</b>,
                        <b>Максимальное количество в группе 16 человек</b>,
                        <b>В стоимость программы также входят все учебные материалы и пособия которые будут необходимы студентам в процессе обучения. Они будут выданы учащимся в первый день обучения в аудитории университета</b>
                    ]}
                />
            </div>)
        const OnlinePlus =
            (<div >
                <H2TitleWithTwoTexts
                    title={"программа Online плюс"}
                    leftText={<p>
                        Годовая программа Онлайн плюс чешского языка создана с учётом поздней записи на курсы, либо загруженностью посольств и консульств, что в свою очередь может повлиять на поздний приезд абитуриента в Прагу на основную программу. Для таких ребят у нас действует программа которая предполагает начало обучения дома в первом семестре с сентября и до декабря он-лайн. Второй семестр предполагает обучение уже в Праге с 15 января.
                    </p>}
                    rightText={<p>
                        Программа является полностью полноценным обучением, без каких либо ограничений. Мы не являемся сторонниками большого количества программ, зная что на самом деле необходимо нашим ребятам к успешному достижению своей цели. Поэтому наши программы комплексные, как в языковой подготовке, так и полной поддержке ребят в процедуре продления студентческой визы в процессе обучения, процедуре нострификации, контролю обучения и посещаемости.
                    </p>}
                />
                <H3TwoTitlesWithTwoLists
                    leftTitle={"Программа и стоимость"}
                    rightTitle={"В стоимость также включено"}
                    leftItems={[
                        <p>Стоимость программы:
                            <br/>"Фундамент" - 4950 EUR | 770 часов
                            <br/>"Максимум"  &nbsp; - 5400 EUR | 890 часов
                        </p>,
                        `Начало программы: сентябрь ${year}, окончание: июнь ${year + 1}`,
                        "Начальные знания чешского языка студентов: нулевое",
                        "Достигнутый уровень знания студента B2",
                        "Где: Чешский технический университет в Праге",
                        "Программа подготовки к нострификации школьного аттестата по основным предметам",
                        "Программа подготовки к поступлению в ВУЗы Чешкой республики",
                        "Полную процедуру продления студентческой визы",
                        "Конроль качества обучения, контроль успеваемости и посещений занятий",
                        "Поддержка кураторами курсов на всем протяжении обучения\n"
                    ]}
                    rightItems={[
                        "Запись на программу в университет",
                        "Подготовка пакета документов к получению студенческой визы",
                        "Первичная регистрация в полиции для иностранцев",
                        "Встреча по приезду и трансфер до места проживания",
                        "Помощь в подготовке документов к нострификации школьного аттестата",
                        "Подача документов на нострификацию",
                        "После приезда всех студентов в Прагу, познавательные и информативные экскурсии по Праге",
                        "Консультации и оформление медицинского страхования (страхование оплачивается отдельно)",
                        "Предоставление сим-карты мобильного оператора с первичным балансом\n"
                    ]}
                />
                <ListWithBackground
                    items={[
                        <b>Экзаменационный период за первое полугодие 15.12.{year} — 22.12.{year + 1}</b>,
                        <b>Зимние каникулы 22.12.{year} — 11.01.{year + 1}</b>,
                        <b>Экзаменационный период за второе полугодие 20.06.{year} — 30.06.{year + 1}</b>,
                        <b>Максимальное количество в группе 16 человек</b>,
                        <b>В стоимость программы также входят все учебные материалы и пособия которые будут необходимы студентам в процессе обучения. Они будут выданы учащимся в первый день обучения в аудитории университета</b>
                    ]}
                />
            </div>)
        const Master =
            (<div >
                <H2TitleWithTwoTexts
                    title={"программа Магистр"}
                    leftText={<p>
                        Данная программа учитывает специфику поступления в ВУЗы Чешской республики с уже законченным высшим образованием полученным дома. В данном случае необходимости в нострификации школьного аттестата нет. Как и нет в вступительных экзаменов которые необходимы при поступлении на первый курс. На первый план выходит знание чешского языка до уровня Б2, ведь у некоторых специальностей вас могут ждать апробационные экзамены, как правило состоящие из двух частей (например - медицинское направление). Письменной части, и устной. И самое главное, сама учёба, где именно устойчивое знание языка, поможет не быть в числе отстающих.
                    </p>}
                    rightText={<p>
                        Признание диплома о высшем образовании в выбранном ВУЗе подходящем по специфике и направлению образованию полученному дома, проходит по заявлению абитуриента в выбранный ВУЗ, и длиться до двух месяцев. Комиссия после рассмотрения и сравнения учебных программ, учебных планов, пройденных вами дисциплин, на основании соответствия чешской программе, одобряет ваш диплом. Медицинские дипломы, либо дипломы связанные с юриспруденцией, там всё гораздо сложнее. С не всегда гарантированным результатом как по времени, так и конечному итогу. В любом случае, кто бы что не обещал, либо говорил, у магистров всегда не первом плане чешский язык, и его качество.
                    </p>}
                />
                <H3TwoTitlesWithTwoLists
                    leftTitle={"Программа и стоимость"}
                    rightTitle={"В стоимость также включено"}
                    leftItems={[
                        <p>Стоимость программы:
                            <br/>"Фундамент" - 4950 EUR | 770 часов
                            <br/>"Максимум"  &nbsp; - 5700 EUR | 890 часов
                        </p>,
                        `Начало программы: сентябрь ${year}, окончание: июнь ${year + 1}`,
                        "Начальные знания чешского языка студентов: нулевое",
                        "Достигнутый уровень знания студента B2",
                        "Где: Чешский технический университет в Праге",
                        "Полное сопровождение при подаче заявления и консультации",
                        "Полную процедуру продления студентческой визы",
                        "Конроль качества обучения, контроль успеваемости и посещений занятий",
                        "Поддержка кураторами курсов на всем протяжении обучения\n"
                    ]}
                    rightItems={[
                        "Запись на программу в университет",
                        "Подготовка пакета документов к получению студенческой визы",
                        "Первичная регистрация в полиции для иностранцев",
                        "Встреча по приезду и трансфер до места проживания",
                        "После приезда всех студентов в Прагу, познавательные и информативные экскурсии по Праге",
                        "Консультации и оформление медицинского страхования (страхование оплачивается отдельно)",
                        "Предоставление сим-карты мобильного оператора с первичным балансом\n"
                    ]}
                />
                <ListWithBackground
                    items={[
                        <b>Экзаменационный период за первое полугодие 15.12.{year} — 22.12.{year + 1}</b>,
                        <b>Зимние каникулы 22.12.{year} — 11.01.{year + 1}</b>,
                        <b>Экзаменационный период за второе полугодие 20.06.{year} — 30.06.{year + 1}</b>,
                        <b>Максимальное количество в группе 16 человек</b>,
                        <b>В стоимость программы также входят все учебные материалы и пособия которые будут необходимы студентам в процессе обучения. Они будут выданы учащимся в первый день обучения в аудитории университета</b>
                    ]}
                />
            </div>)

        return (
            <div>
                <AdvHeader
                    title={"Программы"}
                    text={<p>
                        В Чешской республике действует положение о высших учебных заведениях. Согласно этому Закону студенты, не несут расходов, связанных с обучением.
                    </p>}
                    color={"#3C8DAD"}
                    url={programsHeader}
                    btn={"НАШИ ПРОГРАММЫ"}
                    aboveTitle={"Стимул учиться с нами"}
                />
                <BaseUnderHeader
                    title={"Качественно и надежно"}
                />
                <div className={"programs-container"}>
                    {/*<H2TitleAndTextWithSideMenu*/}
                    {/*    title={"Коротко о важном"}*/}
                    {/*    text={<p>*/}
                    {/*        У нас действует положение Закона о высших учебных заведениях Чехии.*/}
                    {/*        Согласно этому Закону студенты, обучающиеся по программам на получение звания бакалавра, магистра и доктора не несут расходов, связанных с обучением. Действует принцип равноправного положения иностранного студента со студентом из Чешской Республики. Это может является стимулом задуматься о получении высшего образования в Чешской республике. Образование которое имеет традиции, и которые формировались в течение многих веков. Но для того, чтобы стать студентом одного из чешских ВУЗов и получить бесплатное образование, вам необходимо владеть чешским языком.*/}
                    {/*    </p>}*/}
                    {/*    items={[*/}
                    {/*        {name: "Lorem ipsum1", to: "#"},*/}
                    {/*        {name: "Lorem ipsum2", to: "#"},*/}
                    {/*        {name: "Lorem ipsum3", to: "#"}*/}
                    {/*    ]}*/}
                    {/*/>*/}
                    <H2TitleWithText
                        title={"Коротко о важном"}
                        text={<p>
                            У нас действует положение Закона о высших учебных заведениях Чехии. Согласно этому Закону студенты, обучающиеся по программам на получение звания бакалавра, магистра и доктора не несут расходов, связанных с обучением. Действует принцип равноправного положения иностранного студента со студентом из Чешской Республики. Это может является стимулом задуматься о получении высшего образования в Чешской республике. Образование которое имеет традиции, и которые формировались в течение многих веков. Но для того, чтобы стать студентом одного из чешских ВУЗов и получить бесплатное образование, вам необходимо владеть чешским языком.
                        </p>}
                    />
                    <TextWithBackground
                        text={<p>
                            <b style={{fontStyle: "italic"}}>
                                Кроме этого, признание чешской образовательной системы обусловлено подписанием Чехией Болонского заявления о создании в Европе Зоны Единого Высшего Образования, в следствии чего дипломы чешских ВУЗов признаются во всех странах ЕС.
                            </b>
                        </p>}
                    />
                    <TitleWithTextAndPhoto
                        path={programsText}
                        title={""}
                        width={"50%"}
                        text={<p>
                            Образование у нас, это полноценное европейское обучение. Кроме этого, признание чешской образовательной системы обусловлено подписанием Болонского заявления о создании в Европе Зоны Единого Высшего Образования, в следствии чего дипломы чешских ВУЗов признаются во всех странах ЕС. Наши программы помогут Вам в том, что бы обучение у нас открыло двери в некоммерческие вузы Чехии, адаптации в стране, и дало старт в Вашей новой жизни в Европе.
                            Целью программ является достижение абитуриентами знания языка на уровне понимания устной и письменной речи, способности реагировать на поставленные вопросы, ознакомление с терминологией, которая понадобится при дальнейшем обучении в чешских вузах. Курсы чешского языка проводятся на базе Технического университета в Праге опытными лекторами – преподавателями Университета, носителями языка, что обеспечит эффективное усвоение учебного материала и гарантированный результат.

                        </p>}
                    />
                    <div className={"programs-container-content"}>
                        <StickyHeaderTitle title={"наши программы"} className={"start"}/>
                        <BaseStickyHeader
                            items={stickyItems}
                            handleChange={this.handleChange}
                        />
                        <BaseContentSlider
                            style={{
                                marginTop: "30px"
                            }}
                            items={[
                                Foundation,
                                Maximum,
                                OnlinePlus,
                                Master
                            ]}
                            current={current}
                            handleTouchStart={this.handleTouchStart}
                            handleTouchMove={this.handleTouchMove}
                        />
                        <H3TwoTitlesWithTwoLists
                            style={{marginTop: "-5px"}}
                            leftTitle={"В стоимость курсов не входит"}
                            rightTitle={""}
                            leftItems={[
                                "В стоимость курсов не входит:",
                                "Медицинский страховой полис",
                                "Стоимость услуг региональных партнёров",
                                "Консульские сборы посольств при оформлении виз",
                                "Оплата проезда до Чешской республики (поезд, автобус, самолёт)"
                            ]}
                            rightItems={[
                                "Стоимость проживания в общежитии или квартире",
                                "Оплата питания, проездного, канцелярских товаров",
                                "Оплата дальнейшего обучения в ВУЗе",
                                "Стоимость услуг переводчиков, нотариусов, адвокатов, сборы за легализацию, апостилизацию, нострификацию\n"
                            ]}
                        />
                        <TriplePhoto
                            left={prg1}
                            middle={prg2}
                            right={prg3}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Programs;