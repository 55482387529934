import React, {Component} from 'react';
import "./visa.css"
import FullWidthBgWithText from "../../Template/FullWidthBgWithText/FullWidthBgWithText";
import TitleWithList from "../../Template/TitleWithList/TitleWithList";
import TextWithBackground from "../../Template/TextWithBackground/TextWithBackground";
import visa from "./visa.jpg"
import H2TitleWithTextAndPhoto from "../../Template/TitleWithTextAndPhoto/H2TitleWithTextAndPhoto";
import Path from "../../Components/Path/Path";

class Visa extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <div className={"home-important"}>
                    <Path style={{marginBottom: "-20px"}}/>
                    <H2TitleWithTextAndPhoto
                        path={visa}
                        width={"50%"}
                        title={"Продление студенческой визы"}
                        text={<p>
                            Дорогие студенты и их родители.
                            Позвольте заблаговременно информировать вас о процедуре с которой вам придется столкнуться к концу учебного года.
                            Это продление визы студента на территории Чешской республики. Первая виза получаемая в посольствах либо консульствах Чехии выставляется сроком на 6 месяцев, либо на весь срок обучения
                            &#160;
                            <b>(примечание: Процедура получения первой визы длительная, и надо записыватся зарание).</b>
                            &#160;
                            После приезда в страну, студенты обязаны до истечения срока действия визы, её продлить. На наших курсах мы начинаем готовить ребят к данной процедуре заблаговременно, обычно за 2 месяца до окончания действующей визы. Это как правило апрель месяц. Продление проходит под нашим контролем. Всю необходимую информацию, консультации, а также руководство к необходимым действиям ребята получат на регулярных собраниях, которые проводятся каждую неделю в стенах курсов.
                        </p>}
                    />
                    <FullWidthBgWithText
                        text={<p>
                            <b style={{fontStyle: "italic"}}>
                                Мы поможем нашим студентам в процедуре продления визы в полиции для иностранцев, предоставим все необходимые документы, и постараемся помочь в случае каких либо трудностей в данной процедуре. Важно! Если студенту не исполнилось 18 лет, необходимо согласие родителей или их законного представителя на ваше пребывание в Чехии.
                            </b>
                        </p>}
                    />
                    <TitleWithList
                        title={"Документы необходимые к продлению визы"}
                        items={[
                            "анкета к продлению визы (заполняется в Праге)",
                            "копия паспорта с фотографией",
                            "Подтверждение об обучении",
                            "подтверждение о проживании в общежитии университета (или подтверждение о проживании в съёмном жилье – заверенное подтверждение об обеспечении жильём от собственника жилья - должно быть заверено нотариально)",
                            "2 фотографии 3,5 х 4,5",
                            "справка на фирменном бланке о наличии средств на вашем счёте в банке и копия вашей платёжной карты, прикреплённой к этому счету. Счёт может быть как чешский, так и по месту гражданства. Если вы продлеваете визу на 1 семестр обучения, на счету должно быть не менее 65 000 Крон, при продлении на 1 год – не менее 90 000 Крон",
                            "медицинское страхование на весь срок продления визы"
                        ]}
                    />
                    <TextWithBackground
                        text={<p>
                            <b style={{fontStyle: "italic"}}>
                                Первая виза получается в посольствах либо консульствах ЧР по месту проживания. Все остальные продления студентческиой визы проходят уже в Праге, и никак не связаны с посольством либо консульством в ваших странах.
                            </b>
                        </p>}
                    />
                </div>
            </div>
        );
    }
}

export default Visa;