import React, {Component} from 'react';

import "./contacts.css"
import "./contacts-mobile.css"
import "./ConditionsRoadmap/conditions-roadmap.css"
import "./ConditionsRoadmap/conditions-roadmap-mobile.css"
import bg from "../Home/History/history.jpg"
import ConditionsRoadmap from "./ConditionsRoadmap/ConditionsRoadmap";
import {LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha} from "react-simple-captcha";
import SlimHeader from "../Components/SlimHeader/SlimHeader";
import TitleAndTextWithBackground from "../Template/TitleAndTextWithBackground/TitleAndTextWithBackground";
import H2TitleWithText from "../Template/TitleWithText/H2TitleWithText";
import Path from "../Components/Path/Path";
import emailjs from "emailjs-com";
import {ReactComponent} from "./times.svg";
import Loader from "../Components/Loader/Loader";

class Contacts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phone: "",
            message: "",
            captcha: "",
            nameError: false,
            phoneError: false,
            captchaError: false,
            response: null,
            isLoading: false
        }
        this.doSubmit = this.doSubmit.bind(this);
    }

    componentDidMount () {
        window.scrollTo(0, 0)
        loadCaptchaEnginge(6);
    };

    handleChange = (event) => {
        event.preventDefault();
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state[event.target.name] = event.target.value
    }

    handleName = (event) => {
        event.preventDefault();
        this.setState({name: event.target.value, nameError: false})
    }

    handleCaptcha = (event) => {
        event.preventDefault();
        this.setState({captcha: event.target.value, captchaError: false})
    }

    handleSelect = (event) => {
        this.setState({coursesError: false, courses: event.target.value})
    }
    handlePhone = (event) => {
        let value = event.target.value;
        if (/^(\+)?([ 0-9]){9,16}$/g.test(value) || !Boolean(value)) {
            this.setState({phone: value, phoneError: false})
        } else {
            this.setState({phoneError: true})
        }
    }

    validate = () => {
        const {phone, name} = this.state;
        let phoneError = !(Boolean(phone) && phone !== "");
        let nameError = !(Boolean(name) && name !== "");
        this.setState({
            phoneError: phoneError,
            nameError: nameError,
        })
        return !phoneError && !nameError;
    }

    doSubmit = () => {
        const {captcha} = this.state;
        if (validateCaptcha(captcha, false)) {
            const valid = this.validate();
            if (valid) {
                this.sendEmail(this.state);
            }
            this.setState({captchaError: false})
        }
        else {
            this.setState({captchaError: true})
            loadCaptchaEnginge(6);
        }
    };

    sendEmail = (data) => {
        this.setState({isLoading: true})
        let serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        let templateId = process.env.REACT_APP_EMAILJS_CONTACT_FORM_TEMPLATE_ID;
        let userId = process.env.REACT_APP_EMAILJS_USER_ID;

        const formData = {
            name: data.name,
            phone: data.phone,
            message: data.message
        }
        emailjs.send(
            serviceId, templateId, formData, userId
        )
            .then((result) => {
                this.setState({response: {
                        message: "Спасибо! Ваша заявка принята в работу.",
                        color: "#19D5CE"
                    },
                    isLoading: false,
                    name: "",
                    phone: "",
                    message: "",
                    captcha: ""
                })
            }, (error) => {
                this.setState({response: {
                        message: "Что то пошло не так попробуйте позже.",
                        color: "#F55C47"
                    }, isLoading: false})
            });
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({response: null})
    }


    render() {
        const {name, phone, message, phoneError, captchaError, nameError, response, isLoading} = this.state;

        return (
            <div className="contacts-section">
                <SlimHeader
                    title={"Контакты"}

                    text={
                        <p>
                            Чешский Технический Университет в Праге.
                            <br/>
                            Чешская республика, Прага 6 - Дейвицка, Техническая 2
                        </p>
                    }
                    url={bg}
                />
                <div className="contacts-section-content">
                    <div className="contacts-details">
                        <Path style={{marginBottom: "-20px"}}/>
                        <H2TitleWithText
                            minWidth={"75vw"}
                            title={"НАПИШИТЕ НАМ"}
                            text={<p>
                                Дорогие родители и студенты, мы всегда будем вам рады.
                                <br/>
                                Вы всегда сможете связаться с нами по Вотсапу на номере <b>+420 776-170-817</b>
                                <br/>
                                Мы постараемся ответить на все интересующие Вас вопросы
                                <br/>
                                Либо написать нам на нашу электронную почту <b>info@vertexschool.cz</b> Мы всегда Вам ответим.
                                <br/>
                                Также Вы можете заполнить форму в низу. Это сэкономит Вам время и усилия.
                                <br/>
                                С уважением команда Pragueschool
                            </p>}
                        />
                        <TitleAndTextWithBackground
                            title={"Партнёрам"}
                            text={<p>
                                <b style={{fontStyle: "italic"}}>
                                    Уважаемые друзья, мы всегда рады новым возможностям. В случае, если вам интересна партнёрская программа, мы всегда будем рады обсудить возможности сотрудничества. Вы всегда сможете с нами связаться удобным вам способом.
                                </b>
                            </p>}
                        />
                        <ConditionsRoadmap/>
                    </div>
                    <div className={"contacts-container-wrap"}>
                        <div className={"contacts-left"}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1279.578172827757!2d14.3929863!3d50.1020805!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b953b18badba7%3A0xff08f7e48420083f!2sFaculty%20of%20Electrical%20Engineering%2C%20Czech%20Technical%20University%20in%20Prague!5e0!3m2!1sen!2scz!4v1633471102077!5m2!1sen!2scz"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "relative",
                                    border: "none"
                                }}
                                allowFullScreen="false"
                                loading="lazy"
                            />
                        </div>
                        <div className={"contacts-right"}>
                            <div className="contacts-topic-text">Хотите учиться с нами?</div>
                            <div className="contacts-input-box">
                                <input
                                    type="text"
                                    placeholder="ФИО"
                                    name={"name"}
                                    onChange={this.handleName}
                                    defaultValue={name}
                                    style={{
                                        borderColor: nameError ? "#F55C47" : ""
                                    }}
                                />
                            </div>
                            <div className="contacts-input-box">
                                <input
                                    type="phone"
                                    placeholder="Телефон"
                                    name={"phone"}
                                    onChange={this.handlePhone}
                                    defaultValue={phone}
                                    style={{
                                        borderColor: phoneError ? "#F55C47" : ""
                                    }}
                                />
                            </div>
                            <div className="contacts-input-box contacts-message-box">
                                <textarea
                                    typeof="text"
                                    placeholder="Сообщение"
                                    name={"message"}
                                    onChange={this.handleChange}
                                    defaultValue={message}
                                />
                            </div>
                            <div className={"contacts-input-pair"}>
                                <div className={"contacts-captcha-wrap"}>
                                    <LoadCanvasTemplateNoReload/>
                                </div>
                                <div className={`contacts-captcha-input-box contacts-input-box`}>
                                    <input
                                        type="captcha"
                                        name={"captcha"}
                                        placeholder="Введите текст"
                                        id={"user_captcha_input"}
                                        onChange={this.handleCaptcha}
                                        style={{
                                            borderColor: captchaError ? "#F55C47" : ""
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{justifySelf: "center", alignSelf: "center", marginTop: "12px"}}>
                                {
                                    isLoading ? <Loader/> :
                                        !Boolean(response) ?
                                            <button
                                                className="button application-button"
                                                onClick={this.doSubmit}
                                            >
                                                ОТПРАВИТЬ
                                            </button>
                                            :
                                            <div className={"response-message"} onClick={this.handleClose} style={{background: response.color}}>
                                                <ReactComponent className={"icon"}/>
                                                <p>
                                                    {response.message}
                                                </p>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Contacts;