import React, {Component} from "react";
import HomeHeader from "./MainHeader/HomeHeader";
import Application from "./MainApplication/Application";
import Categories6 from "./6Categories/6Categories";
import Instagram from "../../Components/Instagram/Instagram";

class Main extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <HomeHeader/>
                <Categories6/>
                <Instagram/>
                <Application/>
            </div>
        );
    }
}
export default Main;