import React, {Component} from "react";
import "./icon-and-text-with-background.css"
import "./icon-and-text-with-background-mobile.css"
import {ReactComponent as ExclamationCircle} from "./exclamation-circle.svg";

class IconAndTextWithBackground extends Component {
    render() {
        const {icon, text} = this.props;
        return (
            <div className={"int-with-bg"}>
                <div className="int-with-bg-container">
                    <h3>{
                        Boolean(icon) ? icon : <ExclamationCircle className={"icon"}/>
                    }</h3>

                    {text}
                </div>
            </div>
        )
    }
}

export default IconAndTextWithBackground;