import React, {Component} from 'react';

import "./base-hash-link.css"
import {HashLink} from "react-router-hash-link";

class BaseHashLink extends Component {

    render() {
        const {text, to, className, handleChange} = this.props;
        let hash = window.location.hash;
        let isHash = Boolean(hash && to.endsWith(hash))
        return (
            <div className={`base-hash-link-wrap ${className}`}>
                <HashLink
                    to={to}
                    className={ isHash ? "base-active-item" : ""}
                    onClick={(event) => {
                        handleChange(event, to)
                    }}
                >
                    {text}
                </HashLink>
            </div>
        );
    }
}

export default BaseHashLink;