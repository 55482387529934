import React, {Component} from "react";

import "./vrtx-list.css"
import "./vrtx-list-mobile.css"

class TitleWithList extends Component {
    render() {
        const {title, items, style} = this.props;
        let titleArray = title.split(" ");
        let title1 = titleArray.slice(0, titleArray.length - 1);
        let title2 = titleArray[titleArray.length - 1]
        return (
            <div className={"vrtx-list"} style={style}>
                {
                    Boolean(title) && <div className={"vrtx-list-title"}>
                        <h3>
                            {Boolean(title1) ? (title1.join(" ") + String.fromCharCode(160)) : ""} <span>{`${title2}`}</span>
                        </h3>
                    </div>
                }
                <ul>
                    {
                        items.map(item => (
                            <li><span>•</span>{item}</li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

export default TitleWithList;