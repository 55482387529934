import React, {Component} from "react";
import "./base-title-with-text-and-photo.css"
import "./base-title-with-text-and-photo-mobile.css"

class H3TitleWithTextAndPhoto extends Component {
    render() {
        const {title, text, path, className, noShadow, width} = this.props;
        let titleArray = title.split(" ");
        let title1 = titleArray.slice(0, titleArray.length - 1);
        let title2 = titleArray[titleArray.length - 1]
        let style = {};
        if (!Boolean(noShadow)) {
            style.boxShadow = "0px 0px 6px rgba(0,0,0,0.4)"
        }
        if (Boolean(width)) {
            style.width = width;
        } else {
            style.width = "40%";
        }
        return (
            <div className={`title-with-text-and-photo ${className}`}>
                {
                    Boolean(title) && <div className={"title-with-text-and-photo-title"}>
                        <h3>
                            {Boolean(title1) ? (title1.join(" ") + String.fromCharCode(160)) : ""} <span>{`${title2}`}</span>
                        </h3>
                    </div>
                }
                <div className={"title-with-text-and-photo-text"}>
                    <img
                        src={path}
                        alt={`vertexschool ${title}`}
                        style={style}
                    />
                    {text}
                </div>
            </div>
        )
    }
}

export default H3TitleWithTextAndPhoto;