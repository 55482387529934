import {Component} from "react";
import "./bank.css"
import "./bank-mobile.css"
import BaseHeader from "../../Components/BaseHeader/BaseHeader";
import bank from "./bank.jpg"
import bankText from "../Main/6Categories/bank.jpg"
import bank1 from "./bank1.jpg"
import bank2 from "./bank2.jpg"
import bank3 from "./bank3.jpg"

import H2TitleWithText from "../../Template/TitleWithText/H2TitleWithText";
import FullWidthBgWithText from "../../Template/FullWidthBgWithText/FullWidthBgWithText";
import H3TitleWithTextAndPhoto from "../../Template/TitleWithTextAndPhoto/H3TitleWithTextAndPhoto";
import TriplePhoto from "../../Template/TriplePhoto/TriplePhoto";
import BaseUnderHeader from "../../Components/UnderHeader/BaseUnderHeader";

class Bank extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <BaseHeader
                    title={"Студенческий счёт"}
                    text={<p>
                        Банковский счёт в Чехии, это в первую очередь удобно и надежно. Он обязательно пригодится, как в учёбе, так и повседневной жизни.
                    </p>}
                    url={bank}
                />
                <BaseUnderHeader
                    title={"комфортно и удобно"}
                />
                <div className={"home-bank start"}>
                    <H2TitleWithText
                        title={"Студентческий счёт в банке"}
                        text={<p>
                            После приезда в Прагу, мы стараемся что бы наши студенты поскорее влились в жизнь в Европе. Полноценно и с успехом. В этом им также помогает студентческий счёт, с банковской картой к нему. Это удобно. Ведь часто приходится расплачиваться в магазинах. Платить за общежитие. Телефон, проезд в общественном транспорте, и многое другое, что необходимо студентам в повседневной жизни. Конечно, можно платить карточкой которую ребята везут с собой из дома, подумаете вы. Но всё как обычно в нюансах и особенностях, которые лучше учесть заранее, что откроет более широкие возможности, и снимет некоторые ограничения и неудобства.
                        </p>}
                    />
                    <FullWidthBgWithText
                        text={<p>
                            <b style={{fontStyle: "italic"}}>
                                На пример, часто может возникнуть необходимость возврата каких то средств. Будь то магазин, интернет покупка, общежитие. В этих случаях бесспорно удобнее иметь счёт в Чехии. Это будет намного надёжнее и быстрее. Так же необходимо учесть частое наличие крон у ребят, после обмена денег с долларов либо евро. Мы учим ребят не держать эти сумы дома, или постоянно возить с собой в кошельке, ведь забыть его где-то в спешке легко.
                            </b>
                        </p>}
                    />
                    <H3TitleWithTextAndPhoto
                        title={""}
                        width={"50%"}
                        text={<p>
                            Партнёром курсов уже много лет является старейший чешский банк Česká spořitelna a.s.. Это один из самых крупных и надежных банков в Чехии. Он надёжен, и имеет долгую историю. Счёт для ребят бесплатный. Как его открытие, ведение, оформление банковской карты, а также пользование банкоматами. Наш банк имеет самое большое количество банкоматов в Праге, а так же в Чехии. Карта международная, и будет работать везде. В любом банкомате  Европы и мира.
                            Также банк может предложить нашим студентам на выбор некоторые продукты из своего партфолио. Такие как страхование от несчастных случаев, страхование порчи недвижимости, и многое другое, что может пригодиться студентам в их повседневной жизни.
                            В любом случае, мы всегда рады большему комфорту для наших ребят как в учёбе, так и повседневной жизни. И банк Česká spořitelna a.s., с нами солидарен, и всегда готов с этим помочь.
                        </p>}
                        path={bankText}
                    />
                    <TriplePhoto
                        left={bank1}
                        middle={bank2}
                        right={bank3}
                    />
                </div>
            </div>
        );
    }
}

export default Bank;