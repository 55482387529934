import React, {Component} from 'react';
import "./navbar.css"
import "./navbar-mobile.css"
import BaseNavLink from "./BaseNavLink/BaseNavLink";
import logo from "../../img/logo.png"
import {Link} from "react-router-dom";
import {ReactComponent as Bars} from "./bars.svg";
import {ReactComponent as Times} from "./times.svg";

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fade: false
        }
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll = (event) => {
        let scrolled = window.pageYOffset;
        this.setState({
            fade: Boolean(scrolled > 150)
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const {fade} = this.state;
        const {items} = this.props;
        return (
            <nav className={fade ? "vrtx-navbar vrtx-navbar-fade" : "vrtx-navbar"}>
                <Link to={"/home"} className={"vrtx-navbar-logo"}><img src={logo} alt="vertexschool logo"/></Link>
                <input type="checkbox" id="vrtx-burger"/>
                <label htmlFor="vrtx-burger">
                    <Bars className={"icon"} id={"sign-bars"}/>
                    <Times className={"icon"} id={"sign-times"}/>
                </label>

                <div className={"vrtx-navbar-links"}>
                    {
                        items.map((item, i) => (
                            <BaseNavLink to={item.to} text={item.name}/>
                        ))
                    }
                </div>
            </nav>
        )
    }
}

export default Navbar;