import React, {Component} from 'react';

import "./about-us.css"
import "./about-us-mobile.css"
import FullWidthBgWithText from "../Template/FullWidthBgWithText/FullWidthBgWithText";
import BaseHeader from "../Components/BaseHeader/BaseHeader";
import H2TitleWithTextAndPhoto from "../Template/TitleWithTextAndPhoto/H2TitleWithTextAndPhoto";

import aboutUsHeader from "./about-us-header.jpg"
import aboutUsText from "./about-us-text.jpg";
import H3TwoTitlesWithTwoTexts from "../Template/TwoTitlesWithTwoTexts/H3TwoTitlesWithTwoTexts";
import BaseUnderHeader from "../Components/UnderHeader/BaseUnderHeader";
import Instagram from "../Components/Instagram/Instagram";

class AboutUs extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    
    render() {
        return (
            <div className="about-us-section">
                <BaseHeader
                    title={"О нас"}
                    url={aboutUsHeader}
                    text={<p>
                        В последние годы образование не на словах, а на деле становится приоритетной сферой. Устойчивый интерес к качественному бесплатному образованию в Чешской республике, позволяет вузам страны выходить на новый уровень и открывать новые возможности в обучении.
                    </p>}
                    aboveTitle={"приоритет образования"}
                />
                <BaseUnderHeader title={"VERTEX при ЧТУ в Праге"}/>
                <div className="about-s-container start">
                    <div className="about-us-container-content">
                        <div className="about-us-top about-us-subsection">
                            <H2TitleWithTextAndPhoto
                                title={"Почему мы?"}
                                width={"50%"}
                                text={
                                    <p>
                                        Образование не на словах, а на деле является приоритетной сферой не пути развития как самой молодёжи, так и их родителей. Часто образование за рубежом является альтернативой обучению дома.  Устойчивый интерес к качественному бесплатному образованию в Чешской республике, позволяет выходить на новый уровень и открывать новые возможности в обучении. И ЧТУ в Праге как лидер в области инноваций, научных достижений, много лет являющийся популярнейшим вузом среди наших студентов, не является исключением. Чешский технический университет в Праге, открывает свои двери для студентов, готовых получить качественную языковую подготовку к дальнейшему обучению в университете, или любых других государственных вузах страны на чешском языке.
                                        Университет имеющий долгую историю и традиции, отличающийся пристальным вниманием к качеству образования, готов предложить программы подготовительных курсов чешского языка.
                                    </p>
                                }
                                path={aboutUsText}
                            />
                            <FullWidthBgWithText
                                text={<p>
                                    <b style={{fontStyle: "italic"}}>
                                        Мы позаботимся о необходимой информации к адаптации в новой стране, таких как консультации, подготовка документов, необходимых при получении визы и содействие в ее продлении на территории Чешкой республики, медицинское страхование, проживание.
                                    </b>
                                </p>}
                            />
                            <H3TwoTitlesWithTwoTexts
                                leftTitle={""}
                                rightTitle={""}
                                leftText={<p>
                                    Обучение проводится опытными лекторами, преподавателями Университета – носителями языка, по новейшим методикам изучения иностранных языков, что обеспечивает эффективное усвоение учебного материала. Наши курсы универсальны, и учитывают специфику поступления в любой ВУЗ страны, ведь в конце концов знание чешского языка, это то, что в конечном итоге окажется решающим фактором при поступлении. Без устойчивого языкового уровня B2 либо C1, знания по физике или биологии могут оказаться бесполезными. Также учтена нострификация школьных аттестатов,
                                </p>}
                                rightText={<p>
                                    и подготовка к вступительным экзаменам в ВУЗы страны. Мы не стремимся быть самыми крутыми, большими, и заметными. Число мест у нас ограниченно. Но мы стремимся знать каждого студента в лицо, и знать все проблемы наших ребят, и где им необходима наша помощь. Мы также обязательно поддержим при решении бытовых вопросов. Позаботимся о встрече в аэропорту или на вокзале, в оформлении проездного билета, при подключении к мобильной связи, и многом другом, что необходимо студенту в повседневной жизни в Праге.
                                </p>}
                            />
                            <Instagram/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;
