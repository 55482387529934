import React, {Component} from "react";
import "./title-and-text-with-background.css"
import "./title-and-text-with-background-mobile.css"

class TitleAndTextWithBackground extends Component {
    render() {
        const {title, text, style} = this.props;
        return (
            <div className={"tnt-with-bg"} style={style}>
                <div className="tnt-with-bg-container">
                    <h3>{title}</h3>
                    {text}
                </div>
            </div>
        )
    }
}

export default TitleAndTextWithBackground;