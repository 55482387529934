import React, {Component} from "react";
import "./application.css"
import "./application-mobile.css"
import {ReactComponent} from "./times.svg";
import emailjs from 'emailjs-com';
import {LoadCanvasTemplateNoReload, loadCaptchaEnginge, validateCaptcha} from 'react-simple-captcha';
import Loader from "../../../Components/Loader/Loader";

class Application extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses: "",
            firstName: "",
            secondName: "",
            country: "",
            city: "",
            email: "",
            phone: "",
            message: "",
            captcha: "",
            coursesError: false,
            firstNameError: false,
            secondNameError: false,
            countryError: false,
            cityError: false,
            emailError: false,
            phoneError: false,
            captchaError: false,
            response: null,
            isLoading: false
        }
        this.doSubmit = this.doSubmit.bind(this);
    }

    componentDidMount () {
        loadCaptchaEnginge(6);
    };

    handleChange = (event) => {
        event.preventDefault();
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state[event.target.name] = event.target.value
    }

    handleFirstName = (event) => {
        event.preventDefault();
        this.setState({firstName: event.target.value, firstNameError: false})
    }
    handleSecondName = (event) => {
        event.preventDefault();
        this.setState({secondName: event.target.value, secondNameError: false})
    }
    handleCountry = (event) => {
        event.preventDefault();
        this.setState({country: event.target.value, countryError: false})
    }
    handleCity = (event) => {
        event.preventDefault();
        this.setState({city: event.target.value, cityError: false})
    }

    handleCaptcha = (event) => {
        event.preventDefault();
        this.setState({captcha: event.target.value, captchaError: false})
    }

    handleSelect = (event) => {
        this.setState({coursesError: false, courses: event.target.value})
    }
    handleEmail = (event) => {
        let value = event.target.value;
        if (/(.+)@(.+){2,}\.(.+){2,}/.test(value) || !Boolean(value)) {
            this.setState({email: value, emailError: false})
        } else {
            this.setState({emailError: true})
        }
    }
    handlePhone = (event) => {
        let value = event.target.value;
        if (/^(\+)?([ 0-9]){9,16}$/g.test(value) || !Boolean(value)) {
            this.setState({phone: value, phoneError: false})
        } else {
            this.setState({phoneError: true})
        }
    }

    validate = () => {
        const {phone, email, courses, firstName, secondName, country, city} = this.state;
        let phoneError = !(Boolean(phone) && phone !== "");
        let emailError = !(Boolean(email) && email !== "");
        let coursesError = !(Boolean(courses) && courses !== "");
        let firstNameError = !(Boolean(firstName) && firstName !== "")
        let secondNameError = !(Boolean(secondName) && secondName !== "")
        let countryError = !(Boolean(country) && country !== "")
        let cityError = !(Boolean(city) && city !== "")
        this.setState({
            phoneError: phoneError,
            emailError: emailError,
            coursesError: coursesError,
            firstNameError: firstNameError,
            secondNameError: secondNameError,
            countryError: countryError,
            cityError: cityError
        })
        return !phoneError && !emailError && !coursesError && !firstNameError && !secondNameError && !coursesError && !cityError;
    }

    doSubmit = () => {
        const {captcha} = this.state;
        if (validateCaptcha(captcha, false)) {
            const valid = this.validate();
            if (valid) {
                this.sendEmail(this.state);
            }
            this.setState({captchaError: false})
        }
        else {
            this.setState({captchaError: true})
            loadCaptchaEnginge(6);
        }
    };

    sendEmail = (data) => {
        this.setState({isLoading: true})
        let serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        let templateId = process.env.REACT_APP_EMAILJS_MAIN_FORM_TEMPLATE_ID;
        let userId = process.env.REACT_APP_EMAILJS_USER_ID;
        const formData = {
            courses: data.courses,
            firstName: data.firstName,
            secondName: data.secondName,
            country: data.country,
            city: data.city,
            email: data.email,
            phone: data.phone,
            message: data.message
        }
        emailjs.send(
            serviceId, templateId, formData, userId
        )
            .then((result) => {
                this.setState({response: {
                        message: "Спасибо! Ваша заявка принята в работу. Свяжемся с вами в ближайшее время для детального обсуждения.",
                        color: "#19D5CE"
                    }, isLoading: false,
                    courses: "",
                    firstName: "",
                    secondName: "",
                    country: "",
                    city: "",
                    email: "",
                    phone: "",
                    message: "",
                    captcha: ""
                })
            }, (error) => {
                this.setState({response: {
                        message: "Что то пошло не так попробуйте позже.",
                        color: "#F55C47"
                    }, isLoading: false})
            });
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({response: null})
    }

    render() {
        const { coursesError, emailError, phoneError, captchaError,
            firstNameError, secondNameError, countryError, cityError,
            response, isLoading
        } = this.state;
        return (
            <div className={"application-section"}>
                <div className={"application-container"}>
                    <div className={"application-title"}>
                        <h2>Online заявление</h2>
                    </div>
                    <div className={"application-input-pair"}>
                        <div className={"application-container-select"}>
                            <select
                                name="courses"
                                className={coursesError ? "error" : ""}
                                onChange={this.handleSelect}
                            >
                                <option value="" disabled selected>Курс</option>
                                <option value="Фундамент">Фундамент</option>
                                <option value="Максимум">Максимум</option>
                                <option value="Online плюс">Online плюс</option>
                                <option value="Магистр">Магистр</option>
                            </select>
                        </div>
                        <div className="application-input-box">
                            <input
                                type="text"
                                placeholder="Имя"
                                className={firstNameError ? "error" : ""}
                                onChange={this.handleFirstName}
                            />
                        </div>
                        <div className="application-input-box">
                            <input
                                type="text"
                                className={secondNameError ? "error" : ""}
                                placeholder="Фамилия"
                                onChange={this.handleSecondName}
                            />
                        </div>
                    </div>
                    <div className={"application-input-pair"}>
                        <div className="application-input-box">
                            <input
                                type="text"
                                placeholder="Страна"
                                onChange={this.handleCountry}
                                className={countryError ? "error" : ""}
                            />
                        </div>
                        <div className="application-input-box">
                            <input
                                type="text"
                                placeholder="Город"
                                onChange={this.handleCity}
                                className={cityError ? "error" : ""}
                            />
                        </div>
                        <div className="application-input-box">
                            <input
                                className={emailError ? "error" : ""}
                                type="email"
                                placeholder="E-mail"
                                onChange={this.handleEmail}
                            />
                        </div>
                    </div>
                    <div className={"application-input-pair"}>
                        <div className={"application-input-pair-column"}>
                            <div className="application-input-box">
                                <input
                                    className={phoneError ? "error" : ""}
                                    type="phone"
                                    placeholder="Телефон"
                                    onChange={this.handlePhone}
                                />
                            </div>
                            <div className={"application-input-pair"}>
                                <div className={"application-captcha-wrap"}>
                                    <LoadCanvasTemplateNoReload/>
                                </div>
                                <div className={`application-input-box`}>
                                    <input
                                        className={captchaError ? "error" : ""}
                                        type="captcha"
                                        name={"captcha"}
                                        placeholder="Введите текст"
                                        id={"user_captcha_input"}
                                        onChange={this.handleCaptcha}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="application-message-box">
                            <textarea
                                rows={5}
                                typeof="text"
                                placeholder="Сопроводительная информация к заявлению, вопросы, пожелания."
                                name={"message"}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    {
                        isLoading ? <Loader/> :
                        !Boolean(response) ?
                            <button
                                className="button application-button"
                                onClick={this.doSubmit}
                            >
                                ОТПРАВИТЬ
                            </button>
                            :
                            <div className={"response-message"} onClick={this.handleClose} style={{background: response.color}}>
                                <ReactComponent className={"icon"}/>
                                <p>
                                    {response.message}
                                </p>
                            </div>
                    }
                </div>
            </div>
        );
    }
}
export default Application;