import {Component} from "react";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {default as Spinner} from "react-loader-spinner";

class Loader extends Component {
    render() {
        return (
            <div style={{justifySelf: "center", alignSelf: "center"}}>
                <Spinner
                    type="Puff"
                    color="#19D5CE"
                    height={40}
                    width={40}
                />
            </div>
        );
    }
}

export default Loader;