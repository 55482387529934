import React, {Component} from 'react';
import "./accommodation.css"
import './accommodation-mobile.css'
import BaseStickyHeader from "../Components/StickyHeader/BaseStickyHeader";
import AccommodationItem from "./AccommodationItem/AccommodationItem";
import BaseContentSlider from "../Components/ContentSlider/BaseContentSlider";
import BaseHeader from "../Components/BaseHeader/BaseHeader";

import accommodationHeader from "./accommodation-header.jpg"
import strahov from "./strahov.jpg"
import dejvicka from "./dejvicka.jpg"
import hladkov from "./hlavkov.jpg"
import masarik from "./masarik.jpg"
import orlik from "./orlik.jpg"
import podoli from "./podoli.jpg"
import sinkula from "./sinkula.jpg"
import underHeaderRightPhoto from "./underHeaderRight.jpg"

import Text from "../Template/Text/Text";
import IconAndTextWithBackground from "../Template/IconAndTextWithBackground/IconAndTextWithBackground";
import H2TitleWithText from "../Template/TitleWithText/H2TitleWithText";
import PhotoAndText from "../Template/PhotoAndText/PhotoAndText";
import FullWidthBgWithText from "../Template/FullWidthBgWithText/FullWidthBgWithText";
import StickyHeaderTitle from "../Components/StickyHeaderTitle/StickyHeaderTitle";
import BaseUnderHeader from "../Components/UnderHeader/BaseUnderHeader";

class Accommodation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            currentHash: "#strahov",
            items: [
                {
                    hash: "#strahov",
                    topLeft: {
                        title: "ОБЩЕЖИТИЕ СТРАХОВ",
                        text: <p>
                            Общежитие Страхов является крупнейшим общежитием ЧТУ в Праге. Общая вместимость — более 4700 мест. В основном это двухместные небольшие комнаты, ванные комнаты на каждом этаже. Также на этаже находятся кухни. Классическое общежитие, которое наверное помнят наши Папы и Мамы со своих студентческих лет)).
                            Есть многоцелевые спортивные площадки с искусственным покрытием и ночным освещением, они используются в основном для коллективных игр с мячом, таких как теннис, гандбол, волейбол, баскетбол, футбол и многое другое.
                        </p>
                    },
                    bottomLeft: {
                        title: "В КАМПУСЕ НАХОДЯТСЯ",
                        items: [
                            "Студенческая столовая",
                            "Клуб, ресторан",
                            "Магазины",
                            "Копи-центр",
                            "Спортивная площадка",
                            "Парикмахерская",
                            "Парковка"
                        ]
                    },
                    right: {
                        path: strahov,
                        title: "Ваничкова 7, Прага 6",
                        transport: [
                            "Метро A, станция Дейвицка, автобус \u2116 143",
                            "Метро B, станция Карлово Намести, автобус \u2116 176",
                            "Метро B, станция Андел, автобус \u2116 191"
                        ]
                    }
                },
                {
                    hash: "#podoli",
                    topLeft: {
                        title: "ОБЩЕЖИТИЕ ПОДОЛИ",
                        text: <p>
                            Общежитие расположено в тихом жилом районе Праги 4, в 5 минутах ходьбы от станции метро C Пражскего повстани. Общежитие предоставляет одно-, двух- и трехместные номера блокового типа. Оно не большое, имеет закрытую территорию. Общая вместимость — около 1000 мест. Также на территории есть столовая для студентов, и маленькая таверна. Есть несколько спортивных площадок, которые студенты могут использовать за отдельную оплату.
                        </p>
                    },
                    bottomLeft: {
                        title: "В КАМПУСЕ НАХОДЯТСЯ",
                        items: [
                            "Студенческая столовая",
                            "Спортивная площадка",
                            "Прачка",
                            "Парковка"
                        ]
                    },
                    right: {
                        path: podoli,
                        title: "Na Lysině 12, Прага 4",
                        transport: [
                            "Метро C, станция Пражскего повстани"
                        ]
                    }
                },
                {
                    hash: "#dejvicka",
                    topLeft: {
                        title: "ОБЩЕЖИТИЕ ДЕЙВИЦКА",
                        text: <p>
                            Общежитие находится в тихой части Праги 6. Это одно из общежитий, которое находится рядом с Университетом, в пешей доступности. До Пражского Града примерно 20 минут ходьбы, до центра города 8 минут на метро. Вместимость недавно реконструированного общежития около 150 мест. Данное общежитие одно из самых маленьких. Комнаты одноместные и двухместные. Не имеет спортивных площадок. Общежитие блокового типа, также имеется блок с одноместными комнатами для студентов с нарушениями опорно-двигательной системы.
                        </p>
                    },
                    bottomLeft: {
                        title: "В КАМПУСЕ НАХОДЯТСЯ",
                        items: [
                            "Студенческая столовая",
                            "Клуб, ресторан",
                            "Спортивная площадка общежития Синкулего"
                        ]
                    },
                    right: {
                        path: dejvicka,
                        title: "Зикова 19, Прага 6",
                        transport: [
                            "Метро A, станция Дейвицка",
                        ]
                    }
                },
                {
                    hash: "#orlik",
                    topLeft: {
                        title: "ОБЩЕЖИТИЕ ОРЛИК",
                        text: <p>
                            Общежитие также находится рядом с Университетом, в тихой части Праги 6. До Пражского Града примерно 20 минут ходьбы, до центра города 8 минут на метро. В общежитии более 170 мест. Оно не является большим, что подразумевает его постоянное заполнение. Места в нем освобождаются крайне редко.  Прошло реконструкцией. Также является общежитием блочного типа устройства комнат. Также в пешей доступности находятся все необходимые заведения и магазины.
                        </p>
                    },
                    bottomLeft: {
                        title: "В КАМПУСЕ НАХОДЯТСЯ",
                        items: [
                            "Студенческая столовая",
                            "Клуб, ресторан"
                        ]
                    },
                    right: {
                        path: orlik,
                        title: "Терронска 6, Прага 6",
                        transport: [
                            "Метро A, станция Дейвицка",
                            "Трамвай №2, 20, 26, остановка Витезне намести",
                        ]
                    }
                },
                {
                    hash: "#sinkuleho",
                    topLeft: {
                        title: "ОБЩЕЖИТИЕ СИНКУЛЕГО",
                        text: <p>
                            Общежитие находится также в тихой части Праги 6. В 2000 г. прошло реконструкцией. Находится в пешей доступности от Университета, примерно 5 минут. Также недалеко метро, остановки трамваев и автобусов. В общежитии более 350 мест. Комнаты одно, двух и трехместные. Но также есть комнаты где живут четыре студента. Ванные комнаты находятся на этаже, также на этаже расположены кухни. В округе есть таверны ресторанчики и столовые.
                        </p>
                    },
                    bottomLeft: {
                        title: "В КАМПУСЕ НАХОДЯТСЯ",
                        items: [
                            "Студенческая столовая",
                            "Клуб, ресторан",
                            "Пиццерия",
                            "Спортивная площадка",
                            "Спорт зал",
                            "Читальный зал",
                            "Настольный теннис, фусбол, бильярд"
                        ]
                    },
                    right: {
                        path: sinkula,
                        title: "Зикова 13, Прага 6",
                        transport: [
                            "Метро A, станция Дейвицка"
                        ]
                    }
                },
                {
                    hash: "#chlavkova",
                    topLeft: {
                        title: "ОБЩЕЖИТИЕ ХЛАВКОВА",
                        text: <p>
                            Общежитие находится в центре города. Здание общежития является историческим памятником. В общежитии более 220 мест. Оно также блочного типа. Кухни находятся на этаже. В здании общежития есть холл, а также небольшой спортивный зал. Находится в удобной части города, в округе есть вся необходимая городская инфраструктура, что является неоспоримым преимуществом данного расположения.
                        </p>
                    },
                    bottomLeft: {
                        title: "В КАМПУСЕ НАХОДЯТСЯ",
                        items: [
                            "Салон",
                            "Спортивный зал"
                        ]
                    },
                    right: {
                        path: hladkov,
                        title: "Эйнштейнска 1, Прага 2",
                        transport: [
                            "Метро B, станция Карлово намести",
                            "Автобус №176, остановка Карлово намести",
                            "Трамвай №3, 14, 18, 22, 24, остановка Карлово намести"
                        ]
                    }
                },
                {
                    hash: "#masarka",
                    topLeft: {
                        title: "ОБЩЕЖИТИЕ МАСАРИК",
                        text: <p>
                            Общежитие находится в городском районе Прага 6, а точнее Дейвицка. Оно также находится в пятиминутной пешей доступности от Университета. Вмещаемость общежития — более 550 мест в разных типах комнат. В основном это двух и трёх местные комнаты, блочного типа. Общежитие имеет большую столовую. А также конференц холл. В общежитии имеется также спортзал. Оно также располагает небольшим хостелом на 150 мест.
                        </p>
                    },
                    bottomLeft: {
                        title: "В КАМПУСЕ НАХОДЯТСЯ",
                        items: [
                            "Студенческая столовая",
                            "Ресторан",
                            "Конгресс-холл",
                            "Спортивный зал",
                            "Парковка"
                        ]
                    },
                    right: {
                        path: masarik,
                        title: "Тхакурова 1, Прага 6",
                        transport: [
                            "Метро B, станция Карлово намести",
                            "Трамвай №2, 20, 26, остановка Тхакурова"
                        ]
                    }
                }
            ],
            stickyItems: [
                { name: "СТРАХОВ", to: "#strahov" },
                { name: "ПОДОЛИ", to: "#podoli" },
                { name: "ДЕЙВИЦКА", to: "#dejvicka" },
                { name: "ОРЛИК", to: "#orlik" },
                { name: "СИНКУЛЕГО", to: "#sinkuleho" },
                { name: "ХЛАВКОВА", to: "#chlavkova" },
                { name: "МАСАРИК", to: "#masarka" },
                { name: "СТОИМОСТЬ", to: "#price"}
            ],
            underHeaderLeft: <p>Встреча в Праге и приемлемые условия проживания для студентов и их родителей является одним из немаловажных факторов. Наши студенты проживают в общежитиях Университета. Время доезда до курсов сокращено до минимума, и составляет около 15 минут. Общежития университета оборудованы всем самым необходимым, всем что необходимо студентам в повседневной жизни и обучению. Студенческие общежития Технического Университета находятся в удобных районах города. Удобная инфраструктура Праги позволяет быстро и без проблем добираться до школы. Каждое общежитие имеет свой внутренний распорядок, который затрагивает оплату, правил поведения в общежитии, время работы лиц отвечающих за решение проблем студентов, и т.д., Внутренние правила часто не отличаются от общих правил в студенческих общежитиях мира, но которые лучше не нарушать (шумные вечеринки до утра, алкоголь, грубое общение), и стараться придерживаться во избежание</p>,
            underHeaderRight: <p>негативных проблем, которое может создать не лучшее восприятия студента руководством. У Чешского Технического Университета в Праге семь общежитий, и два заведения гостиничного типа, расположенных в разных частях города. Наши студенты расселяются на общих правах и условиях, существующих для всех студентов, поступивших в университет. Это удобно как самим студентам, так и их родителям. Студенты быстрее вливаются в систему проживания. А родители, так как на наших курсах все оплаты разделены, лучше контролируют расходы своих чад. Студенты оплачивают проживание на кассах общежитий, без каких либо скрытых доплат, по актуальному ценнику проживания установленному Управлением общежитиями Университета для студентов. Мы также не влияем на порядок расселения наших студентов, так это в полной компетенции Управления общежитиями, и происходит в автоматическом порядке по мере заполнения общежитий студентами Университета.</p>,
            phone: false
        }
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    getCurrent = (hash) => {
        const {stickyItems} = this.state;
        return stickyItems.findIndex(item => item.to === hash);
    }

    handleChange = (event, to) => {
        this.setState({currentHash: to, current: this.getCurrent(to)})
    }

    handleNext = () => {
        const {current, stickyItems:items} = this.state;
        let next = current + 1;
        if (next > items.length - 1) {
            next = current;
        }
        window.location.hash = items[next].to
        this.setState({current: next})
    }

    handlePrev = () => {
        const {current, stickyItems:items} = this.state;
        let prev = current - 1;
        if (prev < 0) {
            prev = 0
        }
        window.location.hash = items[prev].to
        this.setState({current: prev})
    }

    getTouches = (event) => {
        return event.touches ||
            event.originalEvent.touches;
    }

    handleTouchStart = (event) => {
        const firstTouch = this.getTouches(event)[0];
        let xDown = firstTouch.clientX;
        let yDown = firstTouch.clientY;
        this.setState({xDown: xDown, yDown: yDown})
    };

    handleTouchMove = (event) => {
        const {xDown, yDown} = this.state;
        if ( ! xDown || ! yDown ) {
            return;
        }

        let xUp = event.touches[0].clientX;
        let yUp = event.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                this.handleNext();
            } else {
                this.handlePrev();
            }
        }
        /* reset values */
        this.setState({xDown: null, yDown: null})
    };

    render() {
        const {current, items, stickyItems} = this.state;
        const Items = items.map((item, i) => (
            <AccommodationItem item={item} key={i}/>
        ))
        Items.push(
            <div>
                <H2TitleWithText
                    fit={true}
                    marginTop={"0px"}
                    title={"Стоимость проживания"}
                    text={<p>
                        Наши студенты оплачивают проживание в общежитиях на прямую в кассах самих общежитий.
                        Месячная стоимость проживания для наших студентов в общежитиях технического университета практически не отличается от стоимости для чешских студентов.
                        Cтоимость проживания в разных общежитиях разнится.
                        Стоимость проживания зависит от нескольких факторов.
                        Зависит от комфортабельности общежития, количества проживающих студентов, и разнообразия предлагаемых услуг.
                        Далее выбора комнат.
                        Общежития блочного типа состоит из двух или трёх жилых комнат в блоке, в которых студенты проживают по 2 человека в каждой комнате, общей кухни, оснащённой необходимой техникой а так же сан. узлом и душевой.
                        Классические общежития более коридорного типа,  где комнаты расположены на этаже. Все удобства также расположены на этаже, и являются общими.
                    </p>}
                />
                <IconAndTextWithBackground
                    text={<p>
                        <b style={{fontStyle: "italic"}}>
                            Стоимость проживания в месяц для студента общежитиях технического университета составляет от 2700 до 5000 чешских крон в зависимости от типа общежития и условий проживания (примерно 110 – 195 евро, цена в евро зависит от моментального курса кроны к евро в ЦБ Чехии). Стоимость устанавливается Управлением общежитий, и оплачивается в кассе общежития самим студентом.
                        </b>
                    </p>}
                />
                <Text
                    text={<p>
                        Также при регистрации студента в системе общежитий Университета, оплачивается одноразовый возвращаемый залог в размере 3000 крон на счёт Управления. А также сбор за обработку информации в размере 300 крон. При непосредственном заселении оплачивается страхование в размере 300 крон и залог на электричество в размере примерно 150 крон.
                    </p>}
                />
            </div>
        )
        return (
            <div className={"accommodation-section"}>
                <BaseHeader
                    title={"Проживание"}
                    text={<p>
                        Встреча в Праге и приемлемые условия проживания для студентов и их родителей является одним из немаловажных факторов. Наши курсы предлагают нашим студентам проживание в общежитиях Университета.
                    </p>}
                    url={accommodationHeader}
                    color={"#39A2DB"}
                    btn={"Наши общежития"}
                    aboveTitle={"немаловажный фактор"}
                />
                <BaseUnderHeader
                    title={"Живём как студенты"}
                />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    paddingTop: "40px",
                    paddingBottom: "40px",
                    background: "#EFEFEF"
                }}>
                    <H2TitleWithText

                        title={"Общежития университета"}
                        text={<p>
                            Встреча в Праге и приемлемые условия проживания для студентов и их родителей является одним из немаловажных факторов.
                            Концепция наших курсов построена вокруг того, что бы наши студенты были максимально приближены к чешскому образованию.
                            Без отрыва как в учебе, так и проживании. Что позволяет им после окончания учебы у нас, быть максимально готовыми к самостоятельной жизни студента, со всеми рисками и подводными камнями. Ребята живут на общих правах и условиях, существующих для всех студентов, поступивших в университет. Это удобно как самим студентам, так и их родителям. Студенты быстрее вливаются в систему проживания. Понимая как работают общежития, их специфику и правила. Что пригодится в будущей учёбе. А родители, так как на наших курсах все оплаты разделены, лучше контролируют расходы своих чад. Что поможет с финансовым планированием.
                        </p>}
                    />
                    <FullWidthBgWithText
                        text={<p>
                            <b style={{fontStyle: "italic"}}>
                                Внутренние правила часто не отличаются от общих правил в студенческих общежитиях мира, но которые лучше не нарушать (шумные вечеринки до утра, алкоголь, грубое общение), и стараться придерживаться во избежание
                                негативных проблем, которое может создать не лучшее восприятия студента руководством.  У Чешского Технического Университета в Праге семь общежитий, и два заведения гостиничного типа, расположенных в разных частях города.
                            </b>
                        </p>}
                    />
                    <PhotoAndText
                        text={<p>
                            <img
                                src={underHeaderRightPhoto}
                                alt="vertexschool accommodation podoli"
                                style={{
                                    float: "left",
                                    width: "50%",
                                    marginRight: "25px"
                                }}
                            />
                            Студенческие общежития Университета находятся в удобных районах города. Удобная инфраструктура Праги позволяет быстро и без проблем добираться до школы. Каждое общежитие имеет свой внутренний распорядок, который затрагивает оплату, правил поведения в общежитии, время работы лиц отвечающих за решение проблем студентов. Студенты оплачивают проживание на кассах общежитий на прямую, без каких либо скрытых доплат, по актуальному ценнику проживания установленному Управлением общежитиями Университета. Мы также не влияем на порядок расселения наших студентов, так это в полной компетенции Управления общежитиями, и происходит в автоматическом порядке по мере заполнения общежитий студентами Университета. Этот порядок установлен руководством Университета, и который действует уже много лет.
                        </p>}
                    />
                </div>
                <StickyHeaderTitle title={"наши общежития"} className={"start"}/>
                <BaseStickyHeader items={stickyItems} handleChange={this.handleChange}/>
                <div className="accommodation-section-content">
                    <div className="accommodation-container">
                        <BaseContentSlider
                            items={Items}
                            current={current}
                            className={"accommodation-custom-content-slider"}
                            handleTouchStart={this.handleTouchStart}
                            handleTouchMove={this.handleTouchMove}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Accommodation;