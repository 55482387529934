import React, {Component} from "react";

import "./title-with-two-texts-mobile.css"
import "./title-with-two-texts.css"

class H2TitleWithTwoTexts extends Component {
    render() {
        const {title, leftText, rightText} = this.props;
        let titleArray = title.split(" ");
        let title1 = titleArray.slice(0, titleArray.length - 1);
        let title2 = titleArray[titleArray.length - 1]
        return (
            <div className={"title-with-two-texts"}>
                {
                    Boolean(title) && <div className={"title-with-two-texts-title"}>
                        <h2>
                            {Boolean(title1) ? (title1.join(" ") + String.fromCharCode(160)) : ""} <span>{`${title2}`}</span>
                        </h2>
                    </div>
                }
                <div className={"title-with-two-texts-text"}>
                    <p className={"title-with-two-texts-left-text"}>
                        {leftText}
                    </p>
                    <p className={"title-with-two-texts-right-text"}>
                        {rightText}
                    </p>
                </div>
            </div>
        )
    }
}

export default H2TitleWithTwoTexts;