import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import "./footer.css"
import "./footer-mobile.css"
import logo from "../../img/logo.png";

class Footer extends Component {

    render() {
        return (
            <div className={"vrtx-footer"}>
                <div className={"vrtx-footer-container"}>
                    <Link to="/home">
                        <img src={logo} alt="vertexschool logo"/>
                    </Link>
                    <p><b>Языковые курсы при Техническом Университете в Праге</b></p>
                </div>
            </div>
        );
    }
}

export default Footer;